import { Component, OnInit, AfterViewInit } from '@angular/core';
import { NavigationDrawerService } from '@ffq-app-shared/components';
import { ServiceConstants } from '@ffq-app-shared/constants/service.constants';
import { Router, Event, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppStoreService } from '@ffq-app-store/app.store.service';
import { SpinnerService } from '@ffq-lib/src/lib/spinner/spinner.service';
import { DataConstants } from '@ffq-app-shared/constants';
import { StateRuleModelService } from '@ffq-app-shared/services';
import { SegmentService } from '@ffq-app-shared/services/segment.service';
import { QuoteInfoParams } from '@ffq-app-shared/model/segment.data.model';
declare const MediaAlphaExchange__load: any;

@Component({
  selector: 'app-intermediate-page',
  templateUrl: './intermediate-page.component.html',
  styleUrls: ['./intermediate-page.component.scss']
})
export class IntermediateComponent implements OnInit, AfterViewInit {
  moduleName: any;
  landingZipCode: any;
  desktopNo: string;
  mobileNo: string;
  teleDesktop: string;
  teleMobile: string;
  sourcePhoneNumber: string;
  /** Only agent quote available */
  isOnlyAgentQuoteAvailable: boolean;
  /** Monetization heading */
  monetizationHeading: string;
  /** Monetization message */
  monetizationMessage: string;
  /** Additional Monetization message */
  monetizationMessageAdditional: string;
  /**
   * Error code of intermediate component
   */
  errorCode: any;
  /**
   * lob of intermediate component
   */
  lob: any;
  /**
   * Zip code of intermediate component
   */
  zipCode: any;
  /**
   * Landing state of intermediate component
   */
   landingState: any;
  /**
   * US554304
   * boolean value to check from projectcode
   * service for BW organic flow for RED States
   */
  isBWOrganicFlowImpl: boolean;
  /**
   * Subscriptions  of business info component
   */
  private subscriptions: Subscription[] = [];

  constructor(private navDrawer: NavigationDrawerService,
              private router: Router,
              private appStoreService: AppStoreService,
              private spinnerService: SpinnerService,
              private segmentService: SegmentService,
              private stateRuleModel: StateRuleModelService
  ) { }

  ngOnInit() {
    this.navDrawer.hideDrawer();
    this.navDrawer.hideEverything();
    /** Subscription for landing state code */
    this.subscriptions.push(this.appStoreService.appDataZip$.subscribe(appDataZip => {
      this.landingZipCode = appDataZip.landingZipCode;
    }));
    this.appStoreService.appError$.subscribe((errorObj: any) => {
      if (errorObj && Object.keys(errorObj).length !== 0) {
        this.errorCode = errorObj.errorInfo.errorCode;
        this.zipCode = errorObj.errorInfo.zipCode;
        this.lob = errorObj.errorInfo.lob;
        this.landingState = errorObj.errorInfo.state;
      }
    });
    this.subscriptions.push(this.appStoreService.getProjectCodeStatus$.subscribe((projectCodeStatus: any) => {
      this.isBWOrganicFlowImpl = projectCodeStatus[`isBrghtLineOrganicFlowImpl`];
    }));
    /** US504337: CA Auto FFQ - Block Online Quote from organic traffic */
    this.validateOnlyOnlineQuoteEligibility();
    this.mediaAlphaPage();
    this.appStoreService.getPhoneNumberFromSrc$.subscribe((phoneNumber: any) => {
      this.sourcePhoneNumber = phoneNumber;
      this.setAgentPhoneNumber();
    });
  }

  validateOnlyOnlineQuoteEligibility(): void {
    if (this.errorCode === 'EG033' || this.isBWOrganicFlowImpl) {
      this.isOnlyAgentQuoteAvailable = true;
      this.monetizationHeading = DataConstants.ONLY_AGENT_QUOTE_AVAILABLE_MSG.heading;
      this.monetizationMessage = DataConstants.ONLY_AGENT_QUOTE_AVAILABLE_MSG.message;
      this.monetizationMessageAdditional = DataConstants.ONLY_AGENT_QUOTE_AVAILABLE_MSG.additionalMessage;
    }
  }

  findAnAgent() {
    /** US521507 - Added segment event for agent searching */
    if (this.stateRuleModel.getNoOnlineQuoteStates(this.landingState)) {
      const quoteInfo: QuoteInfoParams = {
        lob: this.lob,
        zip: this.zipCode,
        state: this.landingState
      };
      this.segmentService.trackCtaClicked('Agent Searched', quoteInfo);
    }
    window.location.href = ServiceConstants.FIND_AN_AGENT;
  }

  /**
   * media alpha page load
   */
  mediaAlphaPage() {
    (window as any).MediaAlphaExchange = {
      type: 'ad_unit',
      placement_id: this.getPlacementId(this.errorCode, this.lob),
      version: '17',
      data: {
        zip: this.zipCode
      }
    };
    MediaAlphaExchange__load('Content');
  }

  /**
   * Gets placement id
   * @ param errCode from startQuote
   * @ returns placement id to return placement_id for media alpha
   */
  getPlacementId(errCode: string, lob: string): string {
    switch (errCode) {
      case 'EG004':
        return 'UvcvA8abPJnyhj4QQXLrfEbdK7kpeg';
      case 'EG025':
        return 'TvoLbKr2HoBJvL5VTTPvJpGJgTXsGQ';
      case 'EG026':
        return 'Nt_yW_xk8zDtDdjxjT6HRAX4RBifcA';
      case 'EG033':
        return this.getPlacementIdBasedOnLob(lob);
      default:
        return 'UvcvA8abPJnyhj4QQXLrfEbdK7kpeg';
    }
  }

  /**
   * Gets lob
   * @ param lob from startQuote
   * @ returns placement id to return placement_id for media alpha
   */
   /** US530810 : Home Digital Monetization - Organic Traffic Block for AUTO, HOME and RENTERs */
  getPlacementIdBasedOnLob(lob: string): string {
    switch (lob) {
      case 'A':
        return 'UvcvA8abPJnyhj4QQXLrfEbdK7kpeg';
      case 'H':
        return 'TvoLbKr2HoBJvL5VTTPvJpGJgTXsGQ';
      case 'R':
        return 'Nt_yW_xk8zDtDdjxjT6HRAX4RBifcA';
      case 'C':
        return 'Nt_yW_xk8zDtDdjxjT6HRAX4RBifcA';
      default:
        return 'UvcvA8abPJnyhj4QQXLrfEbdK7kpeg';
    }
  }

  /**
   * Sets agent phone number
   */
  setAgentPhoneNumber() {
    if (this.sourcePhoneNumber) {
      this.teleDesktop = this.teleMobile = 'tel:' + this.sourcePhoneNumber.replace(/-/g, '');
      this.desktopNo = this.mobileNo = this.sourcePhoneNumber;
    } else {
      this.teleDesktop = 'tel:18002069469';
      this.teleMobile = 'tel:18004209008';
      this.desktopNo = '1-800-206-9469';
      this.mobileNo = '1-800-420-9008';
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.spinnerService.hideLoader();
    });
  }
}
