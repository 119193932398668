import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LandingComponent } from './landing/landing.component';
import { PathNotFoundComponent } from './path-not-found/path-not-found.component';
import { ExistingPolicyComponent } from './shared/components/existing-policy/existing-policy.component';
import { InconvenienceComponent } from './shared/components/inconvenience/inconvenience.component';
import { IntermediateComponent } from './shared/components/intermediate-page/intermediate-page.component';
import { IntermediateBWComponent } from './shared/components/intermediate-bw-page/intermediate-bw-page.component';
import { AutoQuoteComponent } from './shared/components/auto-quote-page/auto-quote-page.component';
import { BusinessQuoteComponent } from './shared/components/business-quote-page/business-quote-page.component';
import { PrintQuoteComponent } from '@ffq-app-auto/quote/components/print-quote/print-quote.component';
import { SubscriptionAgreementComponent } from '@ffq-app-auto/your-info/components/subscription-agreement/subscription-agreement.component';
import { IidKnockoutComponent } from './shared/components/iid-knockout/iid-knockout.component';
const ROUTES: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: LandingComponent
    // redirectTo: 'home'
    // redirectTo: 'auto'
  },
  {
    path: 'landing',
    pathMatch: 'full',
    component: LandingComponent
    // redirectTo: 'home'
    // redirectTo: 'auto'
  },
  {
    path: 'auto',
    // loadChildren: './auto/auto.module#AutoModule'
    loadChildren: () => import('./auto/auto.module').then(m => m.AutoModule),
  },

  {
    path: 'home',
    // loadChildren: './home/home.module#HomeModule'
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'business',
    loadChildren: () => import('./business/business.module').then(m => m.BusinessModule),
  },
  {
    path: 'inconvenience',
    component: InconvenienceComponent
  },
  {
    path: 'iidquotereject',
    component: IidKnockoutComponent
  },
  {
    path: 'maintenance',
    component: AutoQuoteComponent
  },
  {
    path: 'businessquotepage',
    component: BusinessQuoteComponent
  },
  {
    path: 'intermediatepage',
    component: IntermediateComponent
  },
  {
    path: 'intermediatebwpage',
    component: IntermediateBWComponent
  },
  {
    path: 'existing',
    component: ExistingPolicyComponent
  },
  {
    path: 'print',
    component: PrintQuoteComponent
  },
  {
    path: 'subscriptionAgreement',
    component: SubscriptionAgreementComponent
  },
  {
    path: '**',
    pathMatch: 'full',
    component: PathNotFoundComponent
  }
];
export { ROUTES };

@NgModule({
  imports: [RouterModule.forRoot(ROUTES)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
