<div id="block_content-wrapper">
    <!-- US164784 : Qualtrics Embedded data-->
    <div class="qualtrics-data">
        <div id="state">{{landingStateCode}}</div>
        <div id="retrieveIndicator">{{retrieveIndicator}}</div>
        <div id="bwQuote">{{bWQuote}}</div>
        <div id="quoteNumber">{{quoteNumber}}</div>
        <div id="inactivityTimeIndicator">{{isInactivityTimeReached}}</div>
    </div>
<div *ngIf="!showPrintRouter" id="block_content-wrapper">
    <app-header-panel></app-header-panel>
    <div class="predownload-fonts" aria-hidden="true">
        <mat-icon class="predownload-material-font">check</mat-icon>
        <div class="predownload-slate-font">A</div>
        <div class="predownload-slatepro-bk-font">A</div>
        <div class="predownload-slatemedium-font">A</div>
        <div class="predownload-slate-medium-font">A</div>
    </div>
    <mat-sidenav-container (backdropClick)="closeDrawer('backdrop')">
        <mat-sidenav #sidenav disableClose opened="true"  [opened]="openFlag" (mouseenter)="expandSideStrip()" (mouseleave)="collapseSidePanel()" [mode]="matdrawerMode" position="start" autoFocus="false">
            <app-navigation-drawer></app-navigation-drawer>


        </mat-sidenav>

        <mat-sidenav-content  (scroll)="onScrollElement($event)" role="main" class="{{selectedLob=='business' && currentStep==1?'business-lob':''}} app-nav-content" [ngClass]="(hideHeader === true)? 'app-nav-content-no':'app-nav-content'" cdkScrollable>

            <div  id="top-panel-container" *ngIf="!isRateSummaryScreen && ((this.selectedLob !== 'H' && this.selectedLob !== 'home') && (this.selectedLob !== 'renter' && this.selectedLob !== 'R')  && (this.selectedLob !== 'condo' && this.selectedLob !== 'C')  && !rateSummaryRedirection)" class="toppanel-container">

                <div class="navigationStepperTop">
                    <div class="ffq-text-center nav-link-holder">

                        <ng-container *ngFor="let navigationLinkItem of navigationLinks; let index=index;">

                            <a [attr.aria-label]= "(navigationLinkItem.stepNo==currentStep || navigationLinkItem.status==moduleProgressStatus.COMPLETED) ? 'Step'+' '+ navigationLinkItem.stepNo +'-' + navigationLinkItem.title : 'Step'+' '+ navigationLinkItem.stepNo +'-' + navigationLinkItem.title +' '+'disabled'+' '" class="nav-link-item {{navigationLinkItem.stepNo==currentStep?'nav-link-item-active':''}} " (click)="navigate([navigationLinkItem.routerUrl], navigationLinkItem.status, navigationLinks[(index!=0?index-1:index)].status,index + 1)">



                                <span aria-hidden="true" class="nav-drawer_step-number  {{navigationLinkItem.stepNo==currentStep?'nav-drawer_step-number-active':
                  (navigationLinkItem.status==moduleProgressStatus.COMPLETED?'nav-drawer_step-number-completed':'')}}">

                      <ng-container *ngIf="navigationLinkItem.status==moduleProgressStatus.COMPLETED; else elseStepper">
                        <mat-icon >check</mat-icon>
                      </ng-container>
                      <ng-template #elseStepper>{{navigationLinkItem.stepNo}}</ng-template>


                 </span>
                                <span aria-hidden="true"  class="stepTitleText {{navigationLinkItem.stepNo==currentStep?'colorBlue':
                                (navigationLinkItem.status==moduleProgressStatus.COMPLETED?'nav-drawer_step-title-completed':'')}}">{{navigationLinkItem.title}}</span>
                            </a>
                            <div *ngIf="index<navigationLinks.length-1" class="stepDivider"></div>
                        </ng-container>

                    </div>
                </div>

            </div>


            <div id="ffq-app-container">
                <ng-container *ngIf="displayQuotePremium && premiumAmtObj"  >
                <div  class="auto-pi-ma-quote-premium-banner">
                    <div class="auto-pi-ma-quote-premium-header"><h5 >Quote Premium</h5> </div>
                    <div class="auto-pi-ma-quote-premium-amount">${{premiumAmtObj.packgAmntValue}}{{premiumAmtObj.packgAmntFraction}}
                      <span> {{premiumAmtObj.packgDuration}}<sup><a id="auto-pi-ma-footer-note-link" class="auto-module__discount_footer_Link"
                        href="javascript:void(0);" onclick="document.getElementById('ffq-pi-discount-disclaimer').focus()"
                        *ngIf="!premiumAmtObj.isPayInFull" aria-label="Footer note *">*</a></sup></span></div>
                </div>
                <div class="auto-pi-ma-quote-premium-banner-margin"></div>
               </ng-container>
                <router-outlet></router-outlet>
            </div>


        </mat-sidenav-content>
    </mat-sidenav-container>
    <app-ffq-chatbot></app-ffq-chatbot>
</div>
<lib-progress-spinner></lib-progress-spinner>
<div *ngIf="showPrintRouter">
<router-outlet></router-outlet>
</div>