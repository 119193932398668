<mat-form-field [formGroup]="params.formGroup" class="full-width-field form-elements employer-dropdown-underline"
    appearance="fill">
    <mat-label *ngIf="params.placeholder" id="mat-input-fwsField">{{ params.placeholder }}</mat-label>
    <input #search1 [attr.aria-label]='params.ariaLabel' id="mat-input-fwsField" type="text" matInput class="auto-complete-lib upper-case"
        (input)="onInputChanged($event)" [formControl]="myControl" [matAutocomplete]="auto"
        formControlName="{{params.formControllName}}" />
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onSelectionChanged($event)">
        <ng-container *ngIf="filteredOptions">
            <mat-option *ngFor="let option of filteredOptions " [value]="option.matchDisplayItem"
                [attr.data-GTM]="option.gtmTag" class="dropdown-options">
                <p><span [innerHTML]="option.matchDisplayItem | uppercase | bold: inputValue"></span>
                </p>
            </mat-option>
        </ng-container>
    </mat-autocomplete>
    <button type="button" *ngIf="myControl.value" mat-button matSuffix mat-icon-button aria-label="Clear"
        (click)="closeButton()">
        <mat-icon class="close-button">close</mat-icon>
    </button>

    <mat-hint *ngIf="params.assistiveText" align="start"><strong>{{params.assistiveText}}</strong> </mat-hint>

    <lib-form-field-loader *ngIf="params.showLoader"></lib-form-field-loader>

</mat-form-field>