import { Component, ViewChild,  OnInit } from '@angular/core';
import { NavigationDrawerService } from '../navigation-drawer/navigation-drawer.service';

import { Subscription, combineLatest, merge, of} from 'rxjs';
import { YourInfoStoreService } from '@ffq-app-auto/your-info/store/your-info.store.service';
import { AppStoreService } from '@ffq-app-store/app.store.service';
import { DataConstants, ServiceConstants } from '@ffq-app-shared/constants';
import { textChangeRangeIsUnchanged, textChangeRangeNewSpan } from 'typescript';
import { flatMap, mergeAll, map, filter } from 'rxjs/operators';
import { DataService } from './../../../core';
import { getRedirectionFlow } from '@ffq-app-store/app.selectors';
import { HelperService } from '@ffq-app-shared/services/helper.service';
import { MatSidenav } from '@angular/material/sidenav';
import { SpinnerService } from '@ffq-lib/src/lib/spinner/spinner.service';
import { AutoStoreService } from '@ffq-app-auto/store/auto.store.service';
import { AgentData } from '@ffq-app-shared/model/common.data.model';

@Component({
  selector: 'app-header-panel',
  templateUrl: './header-panel.component.html',
  styleUrls: ['./header-panel.component.scss']
})
export class HeaderPanelComponent implements OnInit {

  public isDrawerOpen = true;
  hideHeader = false;
  currentLob = '';
  // hideHeader = false;
  showAsStrip = false;
  isLiveChatOpen = false;
  isActiveChat = false;
  liveChatGTM = 'FFQ_Auto_header_livechat_button';
  businessHourStatus = '';
  chatActiveStatus = '';
  showLiveChat = true;
  isLiveChatEnabled = false;
  isEEImpl: boolean;
  tele: string;
  teleMobile: string;
  desktopNo: string;
  stateCode: string;
  isQuoteRated: boolean;
  agentAssigned: boolean;
  agentDetails = {} as AgentData;
  sourcePhoneNumber: string;
  displayFarmersLoader = false;
  private subscriptions: Subscription[] = [];
  private sidenav: MatSidenav;
  /** US518599 : Externalization control for Monetization projects */
  isTFNProjectImpl: boolean;
  @ViewChild('sidenav') set content(content: MatSidenav) {
    if (content) { // initially setter gets called with undefined
        this.sidenav = content;
        this.navService.setSidenav(this.sidenav);
    }
 }
  constructor(private navDrawer: NavigationDrawerService,
              private navService: NavigationDrawerService,
              private dataService: DataService,
              private yourInfoStoreService: YourInfoStoreService,
              private appStoreService: AppStoreService,
              private autoStoreService: AutoStoreService,
              private helperService: HelperService,
              private spinnerService: SpinnerService,
              ) {
    this.navDrawer.showHideEvent.subscribe(
      (sidePanelEvent) => {
        if (sidePanelEvent === 'hideEverything') {
          this.hideHeader = true;
        } else if (sidePanelEvent === 'hideHeader') {
          this.hideHeader = true;
        } else if (sidePanelEvent === 'showHeader') {
          this.hideHeader = false;
        }
      }
    );
  }


  ngOnInit() {
    let quoteType = null;
    let lastVisitedPage = null;
    let isBusinessServiceStatus = null;
    let redirectionFlow = null;
    this.navService.showHideEvent.subscribe(
      (sidePanelEvent) => {
        if (sidePanelEvent === 'toggleSidePanelStrip') {

          this.showAsStrip = !this.showAsStrip;
        }
      }
    );
    this.toggleNavDrawer();
    this.appStoreService.appDataZip$.subscribe(appDataZip => {
      if (appDataZip.landingStateCode) {
        this.liveChatGTM = `FFQ_Auto_header_${appDataZip.landingStateCode}_chat_button`;
      }
      this.currentLob = appDataZip.quoteType;
      this.stateCode = appDataZip.landingStateCode;
      quoteType = appDataZip.quoteType;
    });

    /**
     * US501061 && US501062: CA FFQ Digital Monetization - CA State TFN Replacement
     * for Agent, Quote, Thank you and print quote pages for desktop and mobile windows
     */
    this.autoStoreService.getEAAgentStatus$.subscribe(eaAgentStatus => {
      this.agentAssigned = eaAgentStatus;
    });

    this.autoStoreService.getBaseRateCallStatus$.subscribe(rateRateCallStatus => {
      this.isQuoteRated = rateRateCallStatus;
      if (this.isQuoteRated) {
        this.appStoreService.getPhoneNumberServiceStatus$.subscribe(phServiceStatus => {
             this.updatePhoneNumber(phServiceStatus);
        });
      }
    });
    this.appStoreService.getLiveChatStatus$.subscribe(isLiveChatEligible => {
      lastVisitedPage = isLiveChatEligible.lastVisitedPage;
      isBusinessServiceStatus = isLiveChatEligible.isWithinBusinessHourStatus;
      redirectionFlow = isLiveChatEligible.redirectionFlow;
      if (isLiveChatEligible.projectCodeStatus != null) {
        this.isLiveChatEnabled = isLiveChatEligible.projectCodeStatus[`isLiveChatImpl`];
      }
      this.showLiveChat = lastVisitedPage !== 'YourInfo';
      this.isLiveChatOpen = sessionStorage.getItem('liveChatOpened') === 'true';
      if (this.isLiveChatEnabled && (lastVisitedPage === 'Vehicle' || redirectionFlow != null)  &&
      sessionStorage.getItem('liveChatOpened') === null && isBusinessServiceStatus === null) {
        sessionStorage.setItem('liveChatOpened', 'false');
        this.appStoreService.agentBusinessHour();
      } else if (lastVisitedPage === 'Quote' && this.isLiveChatEnabled && !this.isLiveChatOpen) {
        const check = setTimeout(() => {
          if (lastVisitedPage === 'Quote' && this.businessHourStatus === 'Y' && !this.isActiveChat) {
            this.openLiveChat();
            clearTimeout(check);
          }
        }, 50000);
      }
    });
    this.appStoreService.isBusinessHour$.subscribe(isWithinBusinessHours => {
      if (isWithinBusinessHours != null) {
        this.checkWithinBusinessHours(isWithinBusinessHours);
      }
    });

    /**
     * US501061 && US501062: CA FFQ Digital Monetization - CA State TFN Replacement
     * for Agent, Quote, Thank you and print quote pages for desktop and mobile windows
     */
    this.appStoreService.appControlData$.subscribe((data) => {
      if ('Completed' === data.defaultRateQuoteCalled) {
        this.isQuoteRated = true;
        this.appStoreService.getPhoneNumberServiceStatus$.subscribe(phServiceStatus => {
            if (phServiceStatus === 'success' || phServiceStatus === 'fail') {
               this.updatePhoneNumber(phServiceStatus);
            }
        });
      }
    });

    this.appStoreService.getPhoneNumberServiceStatus$.subscribe(phServiceStatus => {
      if (phServiceStatus === 'success' || phServiceStatus === 'fail') {
         this.updatePhoneNumber(phServiceStatus);
      }
    });

    this.appStoreService.chatStatus$.subscribe(chatStatusResponse => {
      if (chatStatusResponse != null) {
        this.isChatActive(chatStatusResponse);
      } else {
        this.isActiveChat = true;
      }
    });
    if (this.navDrawer.isLargeScreen() === false) {
      this.isDrawerOpen = false;
    }
    this.spinnerService.getFarmersLoaderStatus().subscribe(statusObj => {
      this.displayFarmersLoader = statusObj.farmersLoaderStatus;
    });
  }

  /**
   * Updates phone number
   */
  updatePhoneNumber(serviceCallStatus) {
    this.appStoreService.getProjectCodeStatus$.subscribe((data: any) => {

      if (data) {
        this.isEEImpl = data[`isEEImpl`];
        this.isTFNProjectImpl = data[`isTFNProjectImpl`];
        this.sourcePhoneNumber = this.helperService.getSrcPhoneNumber(serviceCallStatus);

        if (this.sourcePhoneNumber) {
          // tslint:disable-next-line:max-line-length
          this.tele = this.teleMobile = this.isEEImpl ? 'tel:' + this.sourcePhoneNumber : 'tel:' + this.sourcePhoneNumber.replace(/-/g, '');
          this.desktopNo = this.sourcePhoneNumber;
        }

        if (!this.sourcePhoneNumber) {
          if (this.isEEImpl && !this.isTFNProjectImpl) {
            this.tele = 'tel:1-888-767-4030';
            this.teleMobile = 'tel:1-888-767-4030';
            this.desktopNo = '1-888-767-4030';
            // this.ariaLabelPhoneNumber = '1-888-767-4030';
          } else {
            /**
             * US501061 && US501062: CA FFQ Digital Monetization - CA State TFN Replacement
             * for Agent, Quote, Thank you and print quote pages for desktop and mobile windows
             */
            if (this.isTFNProjectImpl && this.isQuoteRated && !this.agentAssigned) {
              this.teleMobile = DataConstants.TFN_HELP_NUMBER.teleMobile;
              this.desktopNo = DataConstants.TFN_HELP_NUMBER.desktopNo;
              this.tele = DataConstants.TFN_HELP_NUMBER.tele;
            } else {
            this.tele = 'tel:18002069469';
            this.teleMobile = 'tel:18004209008';
            this.desktopNo = '1-800-206-9469';
            // this.ariaLabelPhoneNumber = '1-800-206-9469';
            }
          }
        }
      }
    });
  }

  checkWithinBusinessHours(isWithinBusinessHours) {
    if (isWithinBusinessHours.transactionNotification === null &&
      isWithinBusinessHours.businessHoursInfo.transactionNotification === null) {
        if (isWithinBusinessHours.businessHoursInfo.isWithinBusinesshoursflag === 'Y') {
          this.businessHourStatus = isWithinBusinessHours.businessHoursInfo.isWithinBusinesshoursflag;
          if (sessionStorage.getItem('isAllLiveChatApiCalled') === null) {
            this.appStoreService.checkChatStatus();
          }
        }
      } else {
        this.dataService.checkLiveChatEligible(this.businessHourStatus, this.chatActiveStatus);
      }
  }

  isChatActive(chatStatusResponse) {
    if (chatStatusResponse.transactionNotification === null &&
      chatStatusResponse.quote.transactionNotification === null) {
        this.chatActiveStatus = chatStatusResponse.quote.isActive;
        this.isActiveChat = this.chatActiveStatus !== 'false';
    }
    if (sessionStorage.getItem('isAllLiveChatApiCalled') === null) {
      this.dataService.checkLiveChatEligible(this.businessHourStatus, this.chatActiveStatus);
      sessionStorage.setItem('isAllLiveChatApiCalled', 'true');
    }
  }

  toggleNavDrawer() {
    this.isDrawerOpen = !this.isDrawerOpen;
    if (this.isDrawerOpen) {
      this.navDrawer.showDrawer();
    } else {
      this.navDrawer.hideDrawer();
    }
 }

  openLiveChat() {
    if (sessionStorage.getItem('liveChatOpened') === 'true') {
      return;
    }

    /** Skipping Chat in case this method gets initialted from knockout/inconv page */
    const currentPage = decodeURIComponent(window.location.href.replace(/%20/g, '+'));
    if (currentPage.indexOf('/auto/knockout') > -1 || currentPage.indexOf('/inconvenience') > 1) {
      return;
    }

    combineLatest([this.yourInfoStoreService.yourInfoLoadData$,
      this.appStoreService.appDataZip$]
    ).subscribe(([customerData, zipData]) => {
      const customer = customerData && customerData.customer ? customerData.customer : {};
      const address = customer && customer.address ? customer.address : {};
      const zip = zipData ? zipData : {};
      const chatWindowUrl = ServiceConstants.CHATBOT_URL + 'post';

      if (sessionStorage.getItem('liveChatOpened') === 'false') {
        sessionStorage.setItem('liveChatOpened', 'true');
        this.isLiveChatOpen = true;
      }

      // form post starts
      const url = chatWindowUrl;
      const form = document.createElement('FORM') as HTMLFormElement;
      form.method = 'POST';
      form.style.display = 'none';
      document.body.appendChild(form);
      form.action = url;
      form.id = 'livechat_form';
      form.target = 'live_chat_window';

      // const self = this;
      const topHeight = screen.height - 620;
      const leftWidth = screen.width - 365;
      window.open('about:blank', 'live_chat_window',
      'width=320px, height=502px, left=' + leftWidth + ', top=' + topHeight);

      const input1 = document.createElement('INPUT') as HTMLInputElement;
      input1.type = 'hidden';
      input1.name = 'quote_number';
      input1.value = zip[`quoteNumber`];
      form.appendChild(input1);

      const input2 = document.createElement('INPUT') as HTMLInputElement;
      input2.type = 'hidden';
      input2.name = 'first_name';
      input2.value = customer[`firstName`];
      form.appendChild(input2);

      const input3 = document.createElement('INPUT') as HTMLInputElement;
      input3.type = 'hidden';
      input3.name = 'last_name';
      input3.value = customer[`lastName`];
      form.appendChild(input3);

      const input4 = document.createElement('INPUT') as HTMLInputElement;
      input4.type = 'hidden';
      input4.name = 'street';
      input4.value = address[`street`];
      form.appendChild(input4);

      const input5 = document.createElement('INPUT') as HTMLInputElement;
      input5.type = 'hidden';
      input5.name = 'apartment';
      input5.value = address[`apartment`];
      form.appendChild(input5);

      const input6 = document.createElement('INPUT') as HTMLInputElement;
      input6.type = 'hidden';
      input6.name = 'city';
      input6.value = address[`city`];
      form.appendChild(input6);

      const input7 = document.createElement('INPUT') as HTMLInputElement;
      input7.type = 'hidden';
      input7.name = 'state';
      input7.value = zip[`landingStateCode`];
      form.appendChild(input7);

      const input8 = document.createElement('INPUT') as HTMLInputElement;
      input8.type = 'hidden';
      input8.name = 'zip_code';
      input8.value = zip[`landingZipCode`];
      form.appendChild(input8);

      const input9 = document.createElement('INPUT') as HTMLInputElement;
      input9.type = 'hidden';
      input9.name = 'gender';
      input9.value = customer[`gender`];
      form.appendChild(input9);

      const input10 = document.createElement('INPUT') as HTMLInputElement;
      input10.type = 'hidden';
      input10.name = 'page_name';
      input10.value = this.helperService.getModuleNameByRoute();
      form.appendChild(input10);

      const input11 = document.createElement('INPUT') as HTMLInputElement;
      input11.type = 'hidden';
      input11.name = 'isLiveChat';
      input11.value = 'Y';
      form.appendChild(input11);

      const input12 = document.createElement('INPUT') as HTMLInputElement;
      input12.type = 'hidden';
      input12.name = 'appName';
      input12.value = 'livechat';
      form.appendChild(input12);

      const input13 = document.createElement('INPUT') as HTMLInputElement;
      input13.type = 'hidden';
      input13.name = 'lobs_quoted';
      input13.value = 'auto';
      form.appendChild(input13);
      form.submit();
      // form post ends

      this.dataService.openLiveChat();
    }).unsubscribe();
  }

  toggleNavStrip() {

    this.navDrawer.toggleSidePanelStrip();
    this.navDrawer.hideDrawer();
    const root = this;
    setTimeout(() => {
      root.navDrawer.showDrawer();
    }, 50);
}
}

