import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
  AfterContentChecked,
  OnDestroy,
  AfterViewInit,
  ViewChild,
  ElementRef,
  Renderer2,
  HostListener,
} from '@angular/core';
import { FormInputParam } from '../form-input-params';
import { FormFieldEmitParams } from '../params/form-field-emit-params';
import {
  MatDatepickerIntl,
  MatDatepicker,
} from '@angular/material/datepicker';
import { DateFormats } from './date.adapter';
import {MatCalendarCellCssClasses} from '@angular/material/datepicker';
import { DateService } from 'projects/ffq-lib/src/lib/core/services/date.service';
import {
  MomentDateModule,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';
import moment from 'moment';
import { Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

@Component({
  selector: 'lib-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  // encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: DateFormats },
  ],
})
export class DatePickerComponent implements OnInit, AfterContentChecked, OnDestroy, AfterViewInit {
  /**
   * Input  of date picker component
   */
  @Input() params: FormInputParam;
  /**
   * US521512 : DOB functionality start be year
   */
  @Input() startWithYear: string;
  /**
   * Object  of date picker component
   */
  Object = Object;
  /**
   * Date entered of date picker component
   */
  dateEntered: Date;
  /**
   * Value1  of date picker component
   */
  value1: any;
  /**
   * Mask  of date picker component
   */
  public mask = {
    guide: true,
    showMask: false,
    keepCharPositions: true,
    mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/], // MM/dd/yyyy
  };
  /**
   * Arialabel  of date picker component
   */
  arialabel: string;

  /**
   * Variable declared to handle focus scenario in ADA
   */
  enableFocusRing = false;
  /**
   * Width  of date picker component
   */
  width: number;
  /**
   * Paste value of date picker component
   */
  pasteValue: string;

  /**
   * Value subscription of date picker component
   */
  valueSubscription: Subscription;
  /**
   * Calendar touched of date picker component
   */
  calendarTouched: boolean;
  isMonthChanged = false;
  currentMonth: number;
  currentYear: number;

  /**
   * US255602: Segment Field level tracking
   * Date picker blur event emitter for Segment
   */
  @Output() dateFieldValue: EventEmitter<FormFieldEmitParams> = new EventEmitter();
  lastDateValue: string;
  /**
   * View child of date picker component
   */
  @ViewChild('picker')
  matDatePicker: MatDatepicker<any>;

  /**
   * View child of date picker component
   */
  @ViewChild('matError')
  matError: ElementRef;

  /**
   * Creates an instance of date picker component.
   * @param datePicker datePicker
   * @param dateService dateService
   * @param datePipe datePipe
   */
  constructor(
    public datePicker: MatDatepickerIntl,
    private dateService: DateService,
    private datePipe: DatePipe,
    private renderer: Renderer2
  ) { }

  /**
   * Focus handle
   */
  focusHandle() {
    this.enableFocusRing = true;
    if (this.matError && this.matError.nativeElement) {
      this.matError.nativeElement.ariaHidden = true;
    }
  }

  blurHandle() {
    this.enableFocusRing = false;
    this.params.pageTitle = '';
  }
  /**
   * Focus out
   * @param value value
   */
  focusout(value) {
    if (this.pasteValue !== null && this.pasteValue !== undefined) {
      value = this.pasteValue;
      this.params.formGroup
        .get(this.params.formControllName)
        .setValue(this.pasteValue);
      this.pasteValue = null;
    }
    value = value.replace(/(\r\n|\n|\r)/gm, '');
    const value2 = value.replace(/[^0-9]/g, '').trim();
    this.value1 = value.replace(/_/g, '').trim();
    // if (this.value1 && this.value1.length < 10) {
    //   this.params.formGroup
    //     .get(this.params.formControllName)
    //     .setErrors({ minLength: true });
    // }
    const date = value.split('/');
    date[0] = date[0] ? date[0].replace(/[^0-9]/g, '').trim() : null;
    date[1] = date[1] ? date[1].replace(/[^0-9]/g, '').trim() : null;
    if (
      value2 &&
      value2.length <= 1 &&
      date[0] &&
      date[0].length <= 1 &&
      parseInt(date[0], 10) > 1
    ) {
      date[0] = this.insertCharecterByIndex(date[0], 0, 0).substring(0, 2);
      this.params.formGroup.get(this.params.formControllName).setValue(date[0]);
    } else if (
      value2 &&
      value2.length === 2 &&
      date[0] &&
      date[0].length === 2 &&
      parseInt(date[0], 10) > 12
    ) {
      date[0] = (
        this.insertCharecterByIndex(date[0], 0, 0) + date[0].substring(0, 1)
      ).substring(0, 2);
      this.params.formGroup.get(this.params.formControllName).setValue(date[0]);
    }

    if (
      value2 &&
      value2.length > 2 &&
      value2.length <= 5 &&
      date[1] &&
      date[1].length <= 1 &&
      parseInt(date[1], 10) > 3
    ) {
      date[1] = this.insertCharecterByIndex(date[1], 0, 0).substring(0, 2);
      this.params.formGroup
        .get(this.params.formControllName)
        .setValue(date[0] + '/' + date[1]);
      // tslint:disable-next-line: max-line-length
    } else if (
      (value2 && value2.length > 2 && value2.length <= 5 && date[1] && date[1].length === 2 &&
        parseInt(date[0], 10) === 2 && parseInt(date[1], 10) > 29) ||
      (value2 && value2.length > 2 && value2.length <= 5 && date[1] && date[1].length === 2 &&
        parseInt(date[1].substring(0, 1), 10) === 3 && parseInt(date[1].substring(1, 2), 10) > 1)) {
      date[1] = (this.insertCharecterByIndex(date[1], 0, 0) + date[1].substring(0, 1)).substring(0, 2);
      this.params.formGroup.get(this.params.formControllName).setValue(date[0] + '/' + date[1]);
    }
    // if (this.value1.length === 10 && this.params.ValidateFutureDate) {
    //   const today = new Date(this.datePipe.transform(new Date(), 'MM/dd/yyyy'));
    // tslint:disable-next-line: max-line-length
    // const valueEntered = new Date(this.datePipe.transform(new Date(this.params.formGroup.get(this.params.formControllName).value), 'MM/dd/yyyy'));
    //   if (valueEntered >= today) {
    //     this.params.formGroup.get(this.params.formControllName).setErrors({ isFutureDate: true });
    //   }
    // }
  }

  /**
   * on init
   */
  ngOnInit() {
    this.resetDatePicker();
    window.addEventListener('scroll', this.scrollEvent, true);
    if (this.params.placeholder === 'Date of birth') {
      this.datePicker.openCalendarLabel =
        'Calendar graphical icon to select your date of birth in the format of mmddyyyy';
    } else if (this.params.placeholder === 'Policy expiration date') {
      this.datePicker.openCalendarLabel =
        'Calendar graphical icon to select your Policy expiration date in the format of mmddyyyy';
    } else if (
      this.params.placeholder === 'Date the violation or accident occurred'
    ) {
      this.datePicker.openCalendarLabel =
        'Calendar graphical icon to enter Date the violation or accident occurred in the format of mmddyyyy';
    } else if (this.params.placeholder === 'Select policy start date') {
      this.params.ariaLabel =
        'Select policy start date in the format of mmddyyyy';
      this.datePicker.openCalendarLabel =
        'Calendar graphical icon to Select policy start date in the format of mmddyyyy';
    }
    if (this.params.pageTitle) {
      this.arialabel = this.params.ariaLabel;
      if (this.params.pageTitle.length !== 0) {
        this.params.ariaLabel =
          this.params.pageTitle + ' ' + this.params.ariaLabel;
      }
    }
  }

  /**
   * Gets whether is large screen
   */
  get isLargeScreen() {
    // this.width = window.innerWidth ||document.documentElement.clientWidth ||document.body.clientWidth;
    if (
      /Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      return false;
    } else {
      return true;
    }
  }
  /**
   * after content checked
   */
  ngAfterContentChecked() {

    // tslint:disable-next-line: max-line-length
    if (
      this.params.id === 'carrierExpDate' &&
      this.params.formGroup.value.carrierExpDate &&
      this.params.formGroup.get(this.params.formControllName).valid
    ) {
      const sample = this.params.formGroup.value.carrierExpDate;
      const sample1 = this.dateService.transform(sample, 'MM/dd/yyyy');
      this.params.ariaLabel =
        'Policy expiration date in the format of mmddyyyy is' +
        ' ' +
        sample1 +
        ' ' +
        'which is editable';
      // tslint:disable-next-line: max-line-length
    } else if (
      this.params.id === 'incidentDate' &&
      this.params.formGroup.value.incidentDate &&
      this.params.formGroup.get(this.params.formControllName).valid
    ) {
      const sample = this.params.formGroup.value.incidentDate;
      const sample1 = this.dateService.transform(sample, 'MM/dd/yyyy');
      this.params.ariaLabel =
        'Date the violation or accident occurred in the format of mmddyyyy is' +
        ' ' +
        sample1 +
        ' ' +
        'which is editable';
    } else if (
      this.params.pageTitle !== undefined &&
      this.params.pageTitle.length === 0
    ) {
      this.params.ariaLabel = this.arialabel;
    }
  }

  /**
   * Validates date format
   */
  validateDateFormat(type: string, value: string) {
    this.enableFocusRing = false;
    if (type === 'change') {
      const valuesData = value.split('/');
      valuesData[0] = valuesData[0] ? valuesData[0].replace(/[^0-9]/g, '').trim() : null;
      valuesData[1] = valuesData[1] ? valuesData[1].replace(/[^0-9]/g, '').trim() : null;
      valuesData[2] = valuesData[2] ? valuesData[2].replace(/[^0-9]/g, '').trim() : null;
      if (valuesData[0] && valuesData[0].length <= 1 && parseInt(valuesData[0], 10) > 1) {
        valuesData[0] = this.insertCharecterByIndex(valuesData[0], 0, 0).substring(0, 2);
        this.params.formGroup.get(this.params.formControllName).setValue(valuesData[0] + '/' + valuesData[1] + '/' + valuesData[2]);
      } else if (valuesData[0] && valuesData[0].length === 2 && parseInt(valuesData[0], 10) > 12) {
        valuesData[0] = (this.insertCharecterByIndex(valuesData[0], 0, 0) + valuesData[0].substring(0, 1)).substring(0, 2);
        this.params.formGroup.get(this.params.formControllName).setValue(valuesData[0] + '/' + valuesData[1] + '/' + valuesData[2]);
      }

      if (valuesData[1] && valuesData[1].length <= 1 && parseInt(valuesData[1], 10) > 3) {
        valuesData[1] = this.insertCharecterByIndex(valuesData[1], 0, 0).substring(0, 2);
        this.params.formGroup.get(this.params.formControllName).setValue(valuesData[0] + '/' + valuesData[1] + '/' + valuesData[2]);
      } else if (valuesData[1] && valuesData[1].length === 2 && parseInt(valuesData[1], 10) > 31) {
        valuesData[1] = (this.insertCharecterByIndex(valuesData[1], 0, 0) + valuesData[1].substring(0, 1)).substring(0, 2);
        this.params.formGroup.get(this.params.formControllName).setValue(valuesData[0] + '/' + valuesData[1] + '/' + valuesData[2]);
      }

      this.emitDateValue(this.params.formGroup.get(this.params.formControllName).value);

      // if (this.value1 && this.value1.length === 10 && parseInt(valuesData[2], 10) <= 1000) {
      //   this.params.formGroup.get(this.params.formControllName).setErrors({ matDatepickerMin: true });
      // }

      // value = valuesData[0] + '/' + valuesData[1] + '/' + valuesData[2];
      // if (parseInt(valuesData[0], 10) === 0 || parseInt(valuesData[1], 10) === 0 || parseInt(valuesData[2], 10) === 0) {
      //   this.params.formGroup.get(this.params.formControllName).setErrors({ inValidDate: true });
      // } else if (this.value1 && this.value1.length === 10 && (parseInt(valuesData[0], 10) > 12 || parseInt(valuesData[1], 10) > 31)) {
      //   this.dateEntered = new Date(value);
      //   this.params.formGroup.get(this.params.formControllName).setErrors({ minLength: true });
      // } else if (parseInt(valuesData[0], 10) > 12 || parseInt(valuesData[1], 10) > 31) {
      //   this.params.formGroup.get(this.params.formControllName).setErrors({ minLength: true });
      // } else if (this.value1 && this.value1.length === 10 && valuesData[0] === '02') {
      //   if (valuesData[1] && parseInt(valuesData[1], 10) >= 29 && parseInt(valuesData[2], 10) % 4 !== 0) {
      //     this.params.formGroup.get(this.params.formControllName).setErrors({ minLength: true });
      //   } else if (valuesData[1] && parseInt(valuesData[1], 10) >= 30 && parseInt(valuesData[2], 10) % 4 === 0) {
      //     this.params.formGroup.get(this.params.formControllName).setErrors({ minLength: true });
      //   } else if (parseInt(valuesData[2], 10) > 1000) {
      //     this.dateEntered = new Date(value);
      //     this.setDateValue(this.dateEntered);
      //   }
      //   // tslint:disable-next-line: max-line-length
      // } else if (this.value1 && this.value1.length === 10 &&
      // (parseInt(valuesData[0], 10) === 4 || parseInt(valuesData[0], 10) === 6
      // || parseInt(valuesData[0], 10) === 9 || parseInt(valuesData[0], 10) === 11)) {
      //   if (valuesData[1] && parseInt(valuesData[1], 10) >= 31) {
      //     this.params.formGroup.get(this.params.formControllName).setErrors({ minLength: true });
      //   } else if (parseInt(valuesData[2], 10) > 1000) {
      //     this.dateEntered = new Date(value);
      //     this.setDateValue(this.dateEntered);
      //   }
      // } else if (this.value1 === '') {
      //   this.params.formGroup.get(this.params.formControllName).setErrors({ required: true });
      // } else if (this.value1 && this.value1.length === 10 && parseInt(valuesData[2], 10) > 1000) {
      //   this.dateEntered = new Date(value);
      //   this.setDateValue(this.dateEntered);
      // }
    }
  }

  /**
   * value change
   * @param event event
   */
  valueChange(event) {
    this.params.formGroup.get(this.params.formControllName).setValue(this.datePipe.transform(event.value, 'MM/dd/yyyy'));
    this.emitDateValue(this.params.formGroup.get(this.params.formControllName).value);
  }

  /**
   * US255602: Segment Field level tracking
   */
  emitDateValue(date: string): void {
    if (date && date !== this.lastDateValue) {
      this.lastDateValue = date;
      this.dateFieldValue.emit({ value: date, event: 'Value Added' });
    }
  }

  /**
   * Resets date picker
   */
  resetDatePicker() {
    if (this.params.formGroup.get(this.params.formControllName).value) {
      this.dateEntered = new Date(this.params.formGroup.get(this.params.formControllName).value);
    }
    this.valueSubscription = this.params.formGroup.get(this.params.formControllName).valueChanges.subscribe(value => {
      if (!value) {
        this.dateEntered = null;
      } else {
        this.dateEntered = value;
        const valuesData = value.split('/');
        if (moment(value, 'MM/DD/YYYY').isValid() && value.length === 10) {
          if (valuesData[2]) {
            if (valuesData[2] > 1000) {
              this.dateEntered = new Date(value);
            }
          } else {
            this.dateEntered = new Date(value);
          }
        }
      }
    });
  }

  ngOnDestroy() {
    this.valueSubscription.unsubscribe();
  }
  /**
   * Sets date value
   * @param dateEntered dateEntered
   */
  // setDateValue(dateEntered) {
  //   const today = new Date(this.datePipe.transform(new Date(), 'MM/dd/yyyy'));
  //   if (this.params.ValidateFutureDate && dateEntered && typeof dateEntered === 'object' && dateEntered >= today) {
  //     this.params.formGroup.get(this.params.formControllName).setErrors({ isFutureDate: true });
  //   }
  //   if (!this.params.formGroup.get(this.params.formControllName).invalid && dateEntered && typeof dateEntered === 'object') {
  //     if (this.params.maxDate && this.params.maxDate !== undefined) {
  //       const maxDate = new Date(this.datePipe.transform(this.params.maxDate, 'MM/dd/yyyy'));
  //       if (dateEntered > maxDate) {
  //         this.params.formGroup.get(this.params.formControllName).setErrors({ matDatepickerMax: true });
  //       }
  //     }
  //     if (this.params.minDate && this.params.minDate !== undefined) {
  //       const minDate = new Date(this.datePipe.transform(this.params.minDate, 'MM/dd/yyyy'));
  //       if (dateEntered < minDate) {
  //         this.params.formGroup.get(this.params.formControllName).setErrors({ matDatepickerMin: true });
  //       }
  //     }
  //   }
  // }

  /**
   * Events handler key up
   */
  eventHandlerKeyUp(event) {
    let date1 = event.clipboardData.getData('text/plain');
    date1 = date1.replace(/(\r\n|\n|\r)/gm, '');
    const date = date1.split('/');
    if (date1 && date1.length <= 10 && date[2] && date[2].length === 4) {
      if (date[0] && date[0].length <= 1 && parseInt(date[0], 10) >= 1) {
        date[0] = this.insertCharecterByIndex(date[0], 0, 0).substring(0, 2);
      } else if (date[0] && date[0].length === 2 && parseInt(date[0], 10) > 12) {
        date[0] = (this.insertCharecterByIndex(date[0], 0, 0) + date[0].substring(0, 1)).substring(0, 2);
      }
      if (date[1] && date[1].length <= 1) {
        date[1] = this.insertCharecterByIndex(date[1], 0, 0).substring(0, 2);
      } else if (
        date[1] && date[1].length === 2 && parseInt(date[1], 10) > 31
      ) {
        date[1] = (this.insertCharecterByIndex(date[1], 0, 0) + date[1].substring(0, 1)).substring(0, 2);
      }
      this.pasteValue = `${date[0]}/${date[1]}/${date[2]}`;
      this.params.formGroup.get(this.params.formControllName).setValue(this.pasteValue);
    }
  }

  /**
   * Inserts charecter by index
   * @param input input
   * @param chr input
   * @param position position
   * @returns formatted phone number
   */
  insertCharecterByIndex(input, chr, position) {
    return input.substring(0, position) + chr + input.substring(position);
  }
  // US239073: ADA datepicker fix
  ngAfterViewInit() {
    setTimeout(() => {
      const btn = document.querySelector('#date_picker_toggle .mat-icon-button');
      (btn as HTMLElement).addEventListener('focus', this.onFocus.bind(this));
      (btn as HTMLElement).addEventListener('blur', this.onBlur.bind(this));
    });
  }
/**
 * Determines whether focus on
 * @param event Event
 */
  onFocus(): void {
    if (!this.params.formGroup.get(this.params.formControllName).value && !this.calendarTouched) {
      this.params.formGroup.get(this.params.formControllName).setErrors(null);
    }
    this.calendarTouched = true;
  }
  /**
   * Determines whether blur on
   */
  onBlur(): void {
    if (!this.params.formGroup.get(this.params.formControllName).value && !this.matDatePicker.opened) {
      this.params.formGroup.get(this.params.formControllName).setErrors({required: true});
      if (this.matError && this.matError.nativeElement) {
        this.matError.nativeElement.ariaHidden = false;
      }
    }
   }

   /**
    * Checks if date selected
    */
   checkIfDateSelected(): void {
    if (!this.params.formGroup.get(this.params.formControllName).value) {
      this.params.formGroup.get(this.params.formControllName).setErrors({required: true});
      if (this.matError && this.matError.nativeElement) {
        this.matError.nativeElement.ariaHidden = false;
      }
    }
   }

   /**
    * Streams opened
    * Trigger when mat datepicker is opened
    */
   streamOpened(): void {
    setTimeout(() => {
      const buttons = document.querySelectorAll('mat-calendar .mat-icon-button');
      buttons.forEach(btn =>
        this.renderer.listen(btn, 'click', () => {
          setTimeout(() => {
            this.displayMonth();
          });
        })
      );
      this.displayMonth();
    });
  }

   /**
    * Displays month
    * To set day in aria label for mat datepicker cell
    */
   displayMonth(): void {
    const elements = document.querySelectorAll('.datePicker');
    if (elements.length > 0) {
      const x = elements[0].querySelectorAll('.mat-calendar-body-cell');
      let dateSearch;
      x.forEach(y => {
        dateSearch = y.getAttribute('aria-label');
        const day = this.getDayName(dateSearch, 'en-US');
        if (!day.includes('Invalid')) {
          y.setAttribute('aria-label', dateSearch + ' ' + day + ' ' + 'button');
          }
      });
      const monthYear = elements[0].querySelectorAll('.mat-calendar-period-button.mat-button.mat-button-base');
      monthYear.forEach(item => {
        const month = new Date(dateSearch).getMonth();
        const year = new Date(dateSearch).getFullYear();
        const monthName = this.getMonthNames()[month]?.toUpperCase();
        const ariaLabel = item.getAttribute('aria-label');
        item.setAttribute('aria-label', monthName + ' ' + year + ' ' + ariaLabel);
      });
    }
  }

  /**
   * Gets day name
   * @param dateStr dateStr
   * @param locale locale
   * @returns day day
   */
  getDayName(dateStr: string, locale: string): string {
    const date = new Date(dateStr);
    return date.toLocaleDateString(locale, { weekday: 'long' });
  }

  /**
   * Date class of date picker component
   * To detect month change event
   */
  dateClass = (d: Date): MatCalendarCellCssClasses => {
    const month = new Date(d).getMonth();
    const year = new Date(d).getFullYear();
    if (this.currentMonth !== month || this.currentYear !== year) {
      this.currentMonth = month;
      this.currentYear = year;
      this.isMonthChanged = true;
    }

    if (this.isMonthChanged) {
      this.streamOpened();
    }

    this.isMonthChanged = false;
    return '';
  }

  getMonthNames(): string[] {
    return new Array ( 'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December' );
  }
  // DE70492: Datepickers are not responsive
  @HostListener('document:click', ['$event'])
  onClick = (event: any): void => {
    const elementClass = event.target?.className;
    if (elementClass && !elementClass.includes('mat-calendar')  && !elementClass.includes('mat-button') && this.matDatePicker.opened) {
      this.matDatePicker.close();
    }
  }
// DE70492: Datepickers are not responsive
  scrollEvent = (event: any): void => {
    const elementClass = event.target?.className;
    if (elementClass && !elementClass.includes('mat-calendar')  && !elementClass.includes('mat-button') &&  this.matDatePicker?.opened) {
      this.matDatePicker.close();
    }
  }
  /**
   * US377239: filters weekends schedule agent
   * @param d date
   * @returns boolean
   */
  weekendFilter = (d: Date): boolean => {
    if (this.params.id === 'schedule-agent-date') {
      const day = new Date(d).getDay();
      return day !== 0 && day !== 6 ;
    }
    return true;
  }
}
