import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import { Observable } from 'rxjs';
import { NoLoaderServices, ServiceConstants,
  AutoRentersLoaderServices, ServicesForParnterRequestHeaderAddition } from './shared/constants/service.constants';

/** Store */
import { AppStoreService } from './store/app.store.service';
import { AppState } from './store/app.state';

import { Store } from '@ngrx/store';

/** Moment JS */
import * as moment from 'moment';
import { SpinnerService } from '@ffq-lib/src/lib/spinner/spinner.service';
import { finalize } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { AutoRentersProgressComponent } from '@ffq-app-shared/components/auto-renters-progress/auto-renters-progress.component';
import { FlowParamter } from '@ffq-app-shared/enums/flow.identifier.enum';
import { LastVisitedModule } from '@ffq-app-shared/model/common.data.model';


@Injectable()
export class AppHttpInterceptorService implements HttpInterceptor {
  /** Auto renter loader dialog of app http interceptor service */
  autoRenterLoaderDialog: any;
  /** Determines whether shown auto renters loader */
  isShownAutoRentersLoader = false;
  /** Renters Spinner Instance Count */
  autoRentersSpinnerCount = 0;

  constructor(
    private appStoreService: AppStoreService,
    private spinnerService: SpinnerService,
    private matDialog: MatDialog) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    /** Setting application/json */
    const urlTemplate = request.url.replace(ServiceConstants.BASE_URL, '');
    if (NoLoaderServices.indexOf(urlTemplate) === -1 && this.isSpinnerRequired(urlTemplate, request)) {
      if (this.isAutoRentersLoaderRequired(urlTemplate)) {
        this.showAutoRentersLoader();
      } else {
        this.spinnerService.showLoader();
      }
    }

    request = request.clone({ headers: request.headers.set('Content-Type', 'application/json;charset=UTF-8') });

    /** Setting withCredentials */
    if (ServiceConstants.IS_ACCESS_CONTROL_ENABLED) {
      request = request.clone({ withCredentials: true });
    }


    /** Setting Accept header */
    if (!request.headers.has('Accept')) {
      request = request.clone({ headers: request.headers.set('Accept', 'application/json,text/plain, */*') });
    }

    /** Setting appilication Code */
    request = request.clone({ headers: request.headers.set('applicationCode', 'FFQUI') });


    /** Setting the Authorization paramter if it is available in store */
    this.appStoreService.getAppTokenInfo$.subscribe((data: any) => {
      if (data.tokenInfo) {
        request = request.clone({ headers: request.headers.set('Authorization', data[`tokenInfo`]) });
      }
    });

    /** Setting the Authorization paramter as encrypted data for pi flow */
    if (request.url.indexOf(ServiceConstants.RETRIEVE_SUMMARY_QUOTE) !== -1) {
      this.appStoreService.getLmsRedirectData$.subscribe(lmsData => {
        if (lmsData.isLmsRedirect) {
          request = request.clone({ headers: request.headers.set('windowID', lmsData.windowId) });
        } else {
          this.appStoreService.getEncryptedData$.subscribe((data: any) => {
            if (data) {
              request = request.clone({ headers: request.headers.set('Authorization', data) });
            }
          });
        }
      }).unsubscribe();
    }

    /** Set Windows ID. */
    if (request.url.indexOf(ServiceConstants.RETRIEVE_SUMMARY_QUOTE) === -1) {
      if (request.url.indexOf('common/startQuote') === -1 && request.url.indexOf('common/retrieveQuote') === -1) {
        request = request.clone({ headers: request.headers.set('windowID', sessionStorage.getItem('windowID')) });
      } else {
        const windowId = this.generateWindowId();
        sessionStorage.setItem('windowID', windowId);
        request = request.clone({ headers: request.headers.set('windowID', windowId) });
      }
    }

    request = this.setPartnerReqHeader(request);

    const nextHandle = next.handle(request);
    return nextHandle.pipe(
      finalize(() => ((NoLoaderServices.indexOf(urlTemplate) === -1 && this.isSpinnerRequired(urlTemplate, request))
        ? this.hideLoader() : '')));
  }



  /** Logic to generate the Window ID - START */
  generateWindowId() {
    const windowId = this.guid() + moment().unix() + '_' + moment().toDate().getMilliseconds();
    return windowId;
  }

  /** Custom logic required for Performa rate for Sprinner. 2nd Perform rate and Perform Mono */
   /** Rate (US381116) call should not have spinner */
  isSpinnerRequired(urlTemplate, request: HttpRequest<any>) {

    if (this.skipSecondRate(urlTemplate, request) || this.skipCreditScoreReport(urlTemplate) ||
     this.skipSaveActionTenedica(urlTemplate, request) || this.isIIDKnockout(urlTemplate, request)
      || this.isScheduleAgentAppointment(urlTemplate, request)) {
      return false;
    }
    return true;
  }

  /* US394718: Ignore the loader on iidknock out scenario */
  isIIDKnockout(urlTemplate, request: HttpRequest<any>): boolean {
    return urlTemplate.includes(ServiceConstants.SEND_KNOCKOUT_DETAILS)
      && (request.body && LastVisitedModule.Auto_IIDKnockOut === request.body.lastVisitedPage);
  }

  /* US411544: Ignore the loader on schedule agent appointment scenario */
  isScheduleAgentAppointment(urlTemplate, request: HttpRequest<any>): boolean {
    return urlTemplate.includes(ServiceConstants.SCHEDULE_AGENT_EMAIL) ||
      (request.body === null && urlTemplate.includes(ServiceConstants.VALIDATE_EMAIL));
  }

  /** US381116 : Skip Spinner for 2nd Rate */
  skipSecondRate(urlTemplate, request: HttpRequest<any>): boolean {
    return (urlTemplate.includes(ServiceConstants.PERFORM_RATE) || urlTemplate.includes(ServiceConstants.PERFORM_MONO_RATE))
      && request.body.rateCallType !== 'default';
  }

  /** US381116 : Skip Spinner for Credit Score Report */
  skipCreditScoreReport(urlTemplate): boolean {
    return urlTemplate.includes(ServiceConstants.CREDIT_REPORT_URL);
  }

  skipSaveActionTenedica(urlTemplate, request: HttpRequest<any>): boolean {
   return  urlTemplate.includes(ServiceConstants.VEHICLE_SAVE_ACTIONS_URL)
      && (request?.body && !request?.body?.action?.includes('NC_SUBSCRIPTION'));
  }

  guid() {
    return this.s4() + this.s4() + '-' +
      this.s4() + '-' + this.s4() + '-' + this.s4()
      + '-' + this.s4() + this.s4() + this.s4();
  }

  s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  /** Logic to generate the Window ID - END */

  /**
   * decides which hideloader method needs to be called - hideAutoRenterloader or hideloader from lib
   * @param serviceURL service URL
   */
  hideLoader() {
    this.hideAutoRentersLoader();
    this.spinnerService.hideLoader();
  }
  /**
   * Shows Auto Renters Progress dialog
   */
  showAutoRentersLoader() {
    this.autoRentersSpinnerCount++;
    if (!this.isShownAutoRentersLoader) {
      this.isShownAutoRentersLoader = true;
      this.spinnerService.setExistingSpinnerCount(0);
      this.spinnerService.setSpinStatus(false);
      this.autoRenterLoaderDialog = this.matDialog.open(AutoRentersProgressComponent,
        {
          panelClass: 'auto-renters-progress-dialog',
          data: { dialogTitle: 'Auto + Renters Ins.' },
          disableClose: true,
          autoFocus: false
        });
    }
  }

  /**
   * Hide Auto Renters Progress dialog
   */
  hideAutoRentersLoader() {
    if (this.autoRentersSpinnerCount > 0) {
      this.autoRentersSpinnerCount--;
    }
    if (this.autoRentersSpinnerCount === 0) {
      this.isShownAutoRentersLoader = false;
      if (this.autoRenterLoaderDialog) {
        setTimeout(() => {
          this.autoRenterLoaderDialog.close();
        }, 1000);
      }
    }
  }

  /**
   * Determines whether auto renters loader required is
   * @param serviceUrl Service URL
   * @returns boolean
   */
  isAutoRentersLoaderRequired(serviceUrl) {
    let isAutoRentersLoader = false;
    this.appStoreService.getRequiredAutoRentersLoader$.subscribe(requiredAutoRentersLoader => {
      const currentPage = decodeURIComponent(window.location.href.replace(/%20/g, '+'));
      if (requiredAutoRentersLoader &&
        (currentPage.indexOf('/auto/quote') > -1 && AutoRentersLoaderServices.indexOf(serviceUrl) !== -1)) {
        isAutoRentersLoader = true;
      } else {
        isAutoRentersLoader = false;
      }
    }).unsubscribe();
    return isAutoRentersLoader;
  }

  setPartnerReqHeader(request) {
    let status;
    const urlTemplate = request.url.replace(ServiceConstants.BASE_URL, '');
    this.appStoreService.getRedirectionFlow$.subscribe(data => {
      status = (data === FlowParamter.RETRIEVE_PI_SUMMARY);
    }).unsubscribe();
    if (ServicesForParnterRequestHeaderAddition.includes(urlTemplate) && status) {
      request = request.clone({ headers: request.headers.set('redirection-source', 'partner') });
    }
    return request;
  }

}
