<div *ngIf="hideHeader==false">
    <div ngClass="displayFarmersLoader?'ffq-header-placeholder':' '" id="header_logo-placeholder">

        <div id="header_menu-icon" role="button"  [attr.aria-label]="showAsStrip?'More menu collapsed':'More submenu expanded'" (click)="toggleNavStrip()" (keyup.enter)="toggleNavStrip()" tabindex="0" data-GTM="FFQ_Auto_header_summary_button">
          <img class="ffq-summary-icon" src="../../../../assets/images/summary-icon.png" alt=""/>
          <label aria-label="Quote Summary menu click to collapse or extend left side bar">
            <div aria-hidden="true" class="header_menu-icon-text">MORE</div>
          </label>
        </div>
        <div  id="header_menu-icon-mobile" role="button" (click)="toggleNavDrawer()" [attr.aria-label]="isDrawerOpen?'More submenu expanded':'More menu collapsed'" data-GTM="FFQ_Auto_header_summary_button">
            <img class="ffq-summary-icon" src="../../../../assets/images/summary-icon.png" alt=""/>
          <label aria-label="Quote Summary menu click to collapse or extend left side bar">
            <div aria-hidden="true" class="header_menu-icon-text">MORE</div>
          </label>
        </div>
      <span aria-label="Farmers Insurance  Logo" data-GTM="FFQ_Auto_header_Farmerslogo_link" role="main"> 
      <div aria-hidden ="true" id="header_ffq-logo" title="Farmers &reg;"></div>
      </span>
      
      <div *ngIf="isLiveChatEnabled && !isLiveChatOpen && currentLob!=='B' && currentLob!=='H' && !isActiveChat && 
        businessHourStatus === 'Y' && showLiveChat" [attr.data-GTM]="liveChatGTM"
      tabindex="0" id="header_ffq_chat_icon"  (click)="openLiveChat()" (keydown.enter)="openLiveChat()">
        <label aria-label="Live Chat Icon opens in modal dialog box link">
          <div aria-hidden="true" class="header_menu_chat_icon_text" >LIVE CHAT</div>
        </label>
      </div>

        <div class= "phone_number_mobile">
          <a href={{teleMobile}} tabindex="0"  role="link">
        <div id = "header_ffq_call_icon">
          <label>
            <div class="header_menu_call_icon_text" >CALL</div>
          </label>
        </div>
        </a>
      </div>

  <div class= "phone_number_desktop">
      <a href={{tele}} attr.aria-label=  "Contact farmers representative at  {{desktopNo}} "  tabindex="0"  role="link">
        <div id = "header_ffq_call_icon">
          <label>
            <div aria-hidden="true" class="header_menu_call_icon_text" >{{desktopNo}}</div>
          </label>
        </div>
      </a>
    </div>
    </div>
</div>
