<div *ngIf="showSidePanel && isHomeThankYou==false" class=" {{sidePanelClass}}">

  <div *ngIf="showAsStrip==false else elseShowAsStrip" class="active-page-title" x-ms-format-detection="none">

    {{activePageTitle}}

    <ng-container *ngIf="quoteNumber && enableQuoteNumber">
      #{{quoteNumber}}
    </ng-container>


  </div>


  <ng-template #elseShowAsStrip>
    <div class="active-page-title" tabindex="0" >
      <ng-container *ngIf="quoteNumber">
        #
      </ng-container>
    </div>
  </ng-template>

  <div *ngIf="(this.selectedLob !== 'H' && this.selectedLob !== 'C' && this.selectedLob !== 'R' && !rateSummaryRedirection)" class="navigationStepper">
    <div [ngClass]= "(currentStep == 2 && (this.selectedLob == 'business' || this.selectedLob == 'B')) ? 'ffq-text-center nav-link-holder-business' : 'ffq-text-center nav-link-holder'">

      <ng-container  *ngFor="let navigationLinkItem of navigationLinks; let index=index;">

        <!-- {{navigationLinkItem.stepNo}} - {{stepsCompleted}} - {{navigationLinkItem.stepNo<=stepsCompleted}} -->


        <a class="nav-link-item  {{navigationLinkItem.stepNo==currentStep?'nav-link-item-active':''}} " [attr.data-GTM]="navigationLinkItem.gtmTag"
        (click)="navigate([navigationLinkItem.routerUrl], navigationLinkItem.status, navigationLinks[(index!=0?index-1:index)].status,index + 1)" tabindex="0">
        <div *ngIf="navigationLinkItem.stepNo==currentStep || navigationLinkItem.status==moduleProgressStatus.COMPLETED else disabledlink"  class="sr-only" id="Steptext1" [attr.aria-label]= " 'Step'+' '+ navigationLinkItem.stepNo +'-' + navigationLinkItem.title" role="button"></div>
        <ng-template #disabledlink >
          <div class="sr-only" [attr.aria-label]= " 'Step'+' '+ navigationLinkItem.stepNo +'-' + navigationLinkItem.title +' '+'disabled'+' '" role="button"></div>
        </ng-template>

          <span  aria-hidden="true" class="nav-drawer_step-number {{navigationLinkItem.stepNo==currentStep?'nav-drawer_step-number-active':
            (navigationLinkItem.status==moduleProgressStatus.COMPLETED?'nav-drawer_step-number-completed':'')}}">

            <ng-container *ngIf="navigationLinkItem.status==moduleProgressStatus.COMPLETED; else elseStepper">
                <mat-icon >check</mat-icon>
            </ng-container>

             <ng-template #elseStepper >{{navigationLinkItem.stepNo}}</ng-template>


          </span>
          <span aria-hidden="true" *ngIf="showAsStrip==false"
            class="stepTitleText {{navigationLinkItem.stepNo==currentStep?'colorBlue':
                        (navigationLinkItem.status==moduleProgressStatus.COMPLETED?'nav-drawer_step-title-completed':'')}}">{{navigationLinkItem.title}}</span>
          </a>

        <div *ngIf="index<navigationLinks.length-1" class="stepDivider"></div>
      </ng-container>

    </div>
  </div>

  <div *ngIf="crossSellRedirection && currentStep===5"  class="ffq-sidepanel-cross-sell-lob">
    <div>
      <div class="ffq-sidepanel-cross-sell-text">Your completed Farmers quotes</div>
      <div class="ffq-sidepanel-cross-sell-icon"></div>
    </div>

    <ng-container *ngFor="let displayLOBItem of displayLOBList">
    <a (click)="navigateToLegacy(displayLOBItem.lobCode)" *ngIf="!(notClickableHomeIcon && displayLOBItem.lobCode == 'H')">
    <div tabindex="0" class="ffq-sidepanel-cross-sell-item" >
        <div class="ffq-sidepanel-cross-sell-displaylob-icon {{displayLOBItem.lobClass}}"></div>
        <div class="ffq-sidepanel-cross-sell-displaylob-text">{{displayLOBItem.lobName}}</div>
    </div>
  </a>
</ng-container>
  </div>

  <div *ngIf="(stepsCompleted>1 && this.saveExitMargin == false && !(this.selectedLob == 'business' || this.selectedLob == 'B')) || rateSummaryRedirection" class="ffq-sidepanel-save " (click)="saveAndFinishLater()" (keypress)="saveAndFinishLater()" data-GTM="FFQ_Auto_sidepanel_savefinish_button">
    <div class="ffq-sidepanel-save-icon">
      <img src="../../../../assets/images/icon-save.png" alt = ""/>
    </div>
    <div class="ffq-sidepanel-save-content" >
      <div *ngIf="showAsStrip==false" class="ffq-sidepanel-save-text" tabindex="0" aria-label="Save quote & finish later opens modal dialog box" role="button">Save quote &amp; finish later</div>
    </div>
  </div>

  <div  [ngClass]="(this.selectedLob === 'H' || this.selectedLob === 'C' || this.selectedLob === 'R')? 'ffq-sidepanel-save-home': 'ffq-sidepanel-save-personal' " data-GTM="FFQ_Auto_sidepanel_PerInfoUse_link">
    <div class="ffq-sidepanel-personal-information-icon">
      <img src="../../../../assets/images/link-icon.png" alt = "" />
    </div>
    <div class="ffq-sidepanel-personal-information-use">
      <div *ngIf="showAsStrip==false" class="ffq-sidepanel-personal-information-use-text" >
        <a aria-label="Personal information use opens in new window"  href="{{this.ccpaUrl}}" target= "_blank" tabindex="0">Personal information use</a>
      </div>
    </div>
  </div>


<div  *ngIf="stepsCompleted==1 && (this.selectedLob !== 'H' && this.selectedLob !== 'C' && this.selectedLob !== 'R')" class= "ffq-sidenav-margin"></div>
  <app-agent-details [currentStepNumber]="currentStep"></app-agent-details>

  <app-footer-links></app-footer-links>
</div>