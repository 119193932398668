export enum FlowIndentifierEnum {
    SUBMIT_YOUR_INFO = 'SubmitYourInfo',
    ASSIGN_AGENT = 'AssignAgent',
    PREFILL_DATA = 'PrefillData',
    VALIDATE_ZIP = 'ValidateZIP',
    RATE_QUOTE = 'RateQuote',
    SAVE_DISCOUNT = 'SaveDiscount',
    SAVE_QUOTE = 'SaveQuote',
    RNETERS_RATE = 'RentersRate',
    DRIVER_ASSIGNMENT = 'DriverAssignment',
    BW_AGENT_ASSIGNMENT = 'BWAgentAssignment'
}


export enum ModuleProgressStatus {
    NOT_STARTED = 'Not Started',
    COMPLETED = 'Completed',
    REMOVE = 'Remove'
}

export enum RateQuoteProgressStatus {
    NOT_CALLED = 'Not Called',
    DEFAULT_COMPLETED = 'Default Preform Rate Called',
    RENTERS_RATE_COMPLETED = 'Renters Rate Service Completed',
    COMPLETED = 'Completed'
}

export enum FlowParamter {
    CROSS_SELL = 'CS',
    RETRIEVE_QUOTE = 'RQ',
    OUTBOUND = 'OB',
    RETRIEVE_BUY = 'RB',
    RETRIEVE_SUBMIT = 'RS',
    RETRIEVE_PI_SUMMARY = 'PI',
    REDIRECTIONAL_FWS = 'RD',
    ONE_UI_QUOTE = 'UI'
}

export enum HasError {
    YES = 'Y',
    NO = 'N'
}

/**
 * Service call status
 */
export enum ServiceCallStatus {
    INIT = 'init',
    READY = 'ready',
    TRIGGERED = 'triggered',
    COMPLETED = 'completed',
    POLLING = 'polling',
    FAIL = 'fail'
}
