
import { Injectable } from '@angular/core';

/* rxjs */
import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';

/* NgRx */
import { Actions, ofType, createEffect } from '@ngrx/effects';
import * as fromAppActions from './app.actions';
import { FilterStoreStateService } from '@ffq-app-core/services/filter.store.state.service';
import { FlowIndentifierEnum, ModuleProgressStatus, ServiceCallStatus } from '@ffq-app-shared/enums/flow.identifier.enum';
import { DataService } from '../core/services/data.service';
import { ModuleLandingService } from '@ffq-app-landing/services/module-landing.service';
import { ErrorService } from '@ffq-app-core';
import { props } from '@ngrx/store';

@Injectable()
export class AppEffects {

  /**
   * Creates an instance of app effects.
   * @param actions$ Actions
   * @param dataService DataServices
   * @param filterStoreStateService FilterStoreStateService
   * @param moduleLandingService ModuleLandingService
   */
  constructor(
    private actions$: Actions,
    private dataService: DataService,
    private filterStoreStateService: FilterStoreStateService,
    private moduleLandingService: ModuleLandingService,
    private errorService: ErrorService
  ) { }

  /* START QUOTE */
  startQuote$ = createEffect(() => this.actions$.pipe(
    ofType(fromAppActions.StartQuote),
    mergeMap(action =>
      this.dataService.startQuote$(action.reqPayload).pipe(
        map(response => {
          // service status setting as completed for LMS scenario of PI quotes.
          let callStatus = (response.isRc1Eligible && !response.isRedirectionRequired &&
            (response.quoteNumber === undefined || response.quoteNumber === null)) ?
          ServiceCallStatus.POLLING : ServiceCallStatus.READY;
          if  (callStatus ===  ServiceCallStatus.READY && (response.quoteNumber === undefined || response.quoteNumber === null) &&
          response.redirectionParams) {
            callStatus = ServiceCallStatus.INIT;
          }
          let ctrlData = { initAppRenderServices: response.isRedirectionRequired ? ServiceCallStatus.COMPLETED : callStatus  };
          if (response.agent || response.customer) {
            response = this.filterStoreStateService.copyCustomerAndAgentData(response);
            if (response.agent) {
              ctrlData = Object.assign({}, ctrlData, { assignAgent: 'completed' });
            }
          }
          if (response.knockoutIndicator) {
            ctrlData = Object.assign({}, ctrlData, {
              initAppRenderServices: ServiceCallStatus.INIT,
              isStartQuoteErrorAWSKnockout: true,
              knockoutMessage: response.knockoutBean.knockOutDesc,
              knockoutCode: response.knockoutBean.knockOutCode
            });
          }
          sessionStorage.setItem('StartQuoteResponseData', JSON.stringify(response));
          sessionStorage.setItem('StartQuoteRequestData', JSON.stringify(action.reqPayload));
          sessionStorage.setItem('StartQuoteResponsectrlData', JSON.stringify(ctrlData));
          sessionStorage.removeItem('appStore');
          sessionStorage.removeItem('autoStore');
          return fromAppActions.StartQuoteSuccess({ respData: response, reqPayload: action.reqPayload, ctrlData });
        }),
        catchError(err => of(fromAppActions.StartQuoteFail({ respErr: err }))
        )
      )
    )
  )
  );

  /* GET SOURCE PHONE NUMBER */
  SourcePhoneNumberService$ = createEffect(() => this.actions$.pipe(ofType(fromAppActions.SourcePhoneNumberService),
    mergeMap((action) =>
      this.dataService.getSourcePhoneNumber$(action.reqPayload).pipe(
        map(response => fromAppActions.SourcePhoneNumberServiceSuccess({ respData: response ,
                     ctrlData: { PhoneNumberServiceStatus: 'success' } })),
        catchError(err => of(fromAppActions.SourcePhoneNumberServiceFail({
           ctrlData: { PhoneNumberServiceStatus: 'fail' }
        })))
      )
    )
  )
  );

  /* RETRIEVE QUOTE */
  retriveQuote$ = createEffect(() => this.actions$.pipe(ofType(fromAppActions.RetrieveQuote),
    mergeMap((action) =>
      this.dataService.retrieveQuote$(action.reqPayload).pipe(
        mergeMap(
          response => response.multiQuoteVO.length ? [
            fromAppActions.MultiQuoteData({ respData: response, request: action.reqPayload })
          ] : response.rtrvRedirectReqd ?
          // to identify if redirection is required or not
          [ fromAppActions.RetrieveQuoteRedirectionData({ respData: response })]
          : [
            // Below action will, identify the data to update the App store and push the data to App store
            // tslint:disable-next-line: max-line-length
            fromAppActions.PopulateAppStoreOnRetrieval({ respData: this.moduleLandingService.createAppStoreDataForRetrieval(response) }),
            // Below action will, store the retrieve data response to session storage after manipulation.
            fromAppActions.PopulateSessionStorageOnRetrieval({
              sessionStorageData: this.moduleLandingService.createSessionStorageDataForRetreival(response),
              navAndRetrieveCtrlData: this.moduleLandingService.getRetrieveDataStatus()
            }),
            fromAppActions.RetrieveQuoteSuccess({ ctrlData: { retrieveQuote: 'success', initAppRenderServices: ServiceCallStatus.READY  } })
          ]
        ),
        catchError(err => of(fromAppActions.RetrieveQuoteFail({
          respErr: this.populateErrorForRQ(err),
          ctrlData: { retrieveQuote: 'Fail' }
        })))
      )
    )
  )
  );

  /* GET PROJECT CODE STATUS */
  getProjectStateCodes$ = createEffect(() => this.actions$.pipe(ofType(fromAppActions.GetProjectCodeStatus),
    mergeMap((action) =>
      this.dataService.getProjectStatusCodes$(action.reqPayload).pipe(
        map(response => fromAppActions.GetProjectCodeStatusSuccess({
          respData: response,
          ctrlData: { projectCodeServiceStatus: 'success' }
        })),
        // tslint:disable-next-line: max-line-length
        catchError(err => of(fromAppActions.GetProjectCodeStatusFail({ respErr: Object.assign({}, { service: 'ProjectCodeService' }, err) })))
      )
    )
  )
  );

  /* GET STATIC CONTENTS */
  staticContentService$ = createEffect(() => this.actions$.pipe(
    ofType(fromAppActions.StaticContentService),
    mergeMap(action =>
      this.dataService.getStaticContent$().pipe(
        map(response => {
          return fromAppActions.StaticContentServiceSuccess({ respData: response,  ctrlData: { staticContentServiceStatus: 'success' } });
        }),
        // tslint:disable-next-line: max-line-length
        catchError(err => of (fromAppActions.StaticContentServiceFail({ respErr: Object.assign({}, { service: 'StaticContentService' }, err )}))
        )
      )
    )
  )
  );

  getCrossSellEncryptedData$ = createEffect(() => this.actions$.pipe(ofType(fromAppActions.GetCrossSellEncryptedData),
    mergeMap((action) =>
      this.dataService.getCrossSellEncryptedData$(action.reqPayload).pipe(
        map(response => fromAppActions.GetCrossSellEncryptedDataSuccess({
          respData: response,
          ctrlData: { crossSellEncryptDataServiceStatus: 'success' }
        })),
        catchError(err => of(fromAppActions.GetCrossSellEncryptedDataFail({ respErr: err })))
      )
    )
  )
  );

  /* VALIDATE ZIP CODE */
  validateZipCode$ = createEffect(() => this.actions$.pipe(ofType(fromAppActions.ValidateZipCode),
    mergeMap((action) =>
      this.dataService.validateZipCode$(action.reqPayload).pipe(
        map(response => fromAppActions.ValidateZipCodeSuccess(this.filterStoreStateService.filterResponse(
          {
            respData: response
          }, FlowIndentifierEnum.VALIDATE_ZIP))),
        catchError(err => of(fromAppActions.ValidateZipCodeFail({ respErr: Object.assign({}, { service: 'ValidateZip' }, err) })))
      )
    )
  )
  );

  /*SAVE AND FINISH LATER */
  SaveAndFinishLater$ = createEffect(() => this.actions$.pipe(ofType(fromAppActions.SaveAndFinishLater),
    mergeMap((action) =>
      this.dataService.saveOnEdit$(action.reqPayload).pipe(
        map(response =>
          fromAppActions.SaveAndFinishLaterSuccess({ ctrlData: { saveAndFinishLater: 'success' } }),
        ),
        catchError(err => of(fromAppActions.SaveAndFinishLaterFail({ respErr: err, ctrlData: { saveAndFinishLater: 'fail' } })))
      )
    )
  )
  );

  /* VALIDATE EMAIL  ADDRESS */
  validateEmail$ = createEffect(() => this.actions$.pipe(ofType(fromAppActions.ValidateEmail),
    mergeMap((action) =>
      this.dataService.validateEmail$(action.reqPayload).pipe(
        map(response => fromAppActions.ValidateEmailSuccess({ respData: response })),
        catchError(err => of(fromAppActions.ValidateEmailFail({ respErr: Object.assign({}, { service: 'ValidateEmail' }, err) })))
      )
    )
  )
  );

  /* Sessions keep alive */
  sessionKeepAlive$ = createEffect(() => this.actions$.pipe(ofType(fromAppActions.SessionKeepAlive),
    mergeMap((action) =>
      this.dataService.sessionKeepAlive$(action.reqPayload).pipe(
        map(response => fromAppActions.SessionKeepAliveSuccess({
          ctrlData: { sessionKeepAlive: 'success' }
        })),
        catchError(err => of(fromAppActions.SessionKeepAliveFail({ respErr: err })))
      )
    )
  )
  );

  saveYourInfo$ = createEffect(() => this.actions$.pipe(ofType(fromAppActions.SubmitYourInfo),
    mergeMap((action) =>
      this.dataService.submitYourInfo$(action.reqPayload).pipe(
        mergeMap(response => [
          fromAppActions.SubmitYourInfoSuccess
            (this.filterStoreStateService.filterResponse({
              respData: this.populateSubmitYourInfoDataForStore(action.reqPayload, response), ctrlData: { submitYourInfo: 'success' }
            }, FlowIndentifierEnum.SUBMIT_YOUR_INFO)),
          fromAppActions.UpdateNavStatus({ reqPayload: { yourInfo: ModuleProgressStatus.COMPLETED } })
        ]),
        catchError(err =>
          of(fromAppActions.SubmitYourInfoFail(
            { respErr: Object.assign({}, { service: 'SubmitYourInfo' }, err), ctrlData: { submitYourInfo: 'failed' } })))
      )
    )
  )
  );


  agentAssignment$ = createEffect(() => this.actions$.pipe(ofType(fromAppActions.AssignAgent),
    mergeMap((action) =>
      this.dataService.assignAgent$(action.reqPayload).pipe(
        // tslint:disable-next-line:max-line-length
        map(response =>
          fromAppActions.AssignAgentSuccess(
            this.filterStoreStateService.filterResponse(
              {
                respData: response, ctrlData:
                  { assignAgent: 'completed' }
              }, FlowIndentifierEnum.ASSIGN_AGENT)),
        ),
        catchError(err =>
          of(fromAppActions.AssignAgentFail({ respErr: err, ctrlData: { assignAgent: 'fail' } })))
      )
    )
  )
  );


  startQuoteWithQuoteNumber$ = createEffect(() => this.actions$.pipe(ofType(fromAppActions.StartQuoteWithQuoteNumber),
    mergeMap(action =>
      this.dataService.startQuoteWithQuoteNumber$(action.reqPayload).pipe(
        map(response =>
          fromAppActions.StartQuoteWithQuoteNumberSuccess({
            respData: this.moduleLandingService.createAppStoreDataForCrossSell(response),
            ctrlData: { startQuoteWithQuoteNumber: 'success', projectCodeServiceStatus: '',
              staticContentServiceStatus: '', initAppRenderServices: response.isOneUIEnabled ?
                ServiceCallStatus.INIT : ServiceCallStatus.READY
            }
          })),
        catchError(err => of(fromAppActions.StartQuoteWithQuoteNumberFail({
          respErr: err,
          ctrlData:
            { startQuoteWithQuoteNumber: 'fail' }
        })))
      ))));

  agentBusinessHour$ = createEffect(() => this.actions$.pipe(ofType(fromAppActions.AgentBusinessHour),
    mergeMap((action) =>
      this.dataService.agentBusinessHoursService$().pipe(
        map(response =>
          fromAppActions.AgentBusinessHourSuccess({
            respData: response,
            ctrlData: { agentBusinessHour: 'success' }
          })),
        catchError(err => of(fromAppActions.AgentBusinessHourFail({
          respErr: err,
          ctrlData: { agentBusinessHour: 'fail' }
        })))
      ))));

  checkChatStatus$ = createEffect(() => this.actions$.pipe(ofType(fromAppActions.CheckChatStatus),
    mergeMap((action) =>
      this.dataService.checkChatStatusService$().pipe(
        map(response =>
          fromAppActions.CheckChatStatusSuccess({
            respData: response,
            ctrlData: { isActive: 'true' }
          })),
        catchError(err => of(fromAppActions.CheckChatStatusFail({
          respErr: err,
          ctrlData: { isActive: 'false' }
        })))
      ))));

      getFetchTownList$ = createEffect(() => this.actions$.pipe(ofType(fromAppActions.FetchTownList),
      mergeMap((action) =>
        this.dataService.getFetchTownList$().pipe(
          // tslint:disable-next-line:max-line-length
          map(response =>
            fromAppActions.FetchTownListSuccess(
                {
                  respData: response, ctrlData:
                    { FetchTownList: 'completed' }
                }
          ),
          catchError(err =>
            // tslint:disable-next-line: max-line-length
            of(fromAppActions.FetchTownListFail({ respErr: Object.assign({}, { service: 'FetchTownList' }, err), ctrlData: { FetchTownList: 'fail' } })))
        )
      )
    )));

/* RETRIEVE PI Rate Summary */
retrievePIRateSummary$ = createEffect(() => this.actions$.pipe(
  ofType(fromAppActions.RetrievePIRateSummary),
  mergeMap(action =>
    this.dataService.retrievePIRateSummary$(action.reqPayload).pipe(
      mergeMap(response => [
        fromAppActions.PopulateAppStoreOnRetrieval({ respData: this.moduleLandingService.createAppStoreDataForRetrieval(response) }),
          // Below action will, store the retrieve data response to session storage after manipulation.
          fromAppActions.PopulateSessionStorageOnRetrieval({
            sessionStorageData: this.moduleLandingService.createSessionStorageDataForRetreival(response),
            navAndRetrieveCtrlData: this.moduleLandingService.getRetrieveDataStatus()
          }),
          fromAppActions.UpdateAppControlData({ctrlData: { packageSelected:
            response.persAutoInsQuote.persAutoPolicy.vehicle[0].persAutoCoverage.packageSelected }}),
        fromAppActions.RetrievePIRateSummarySuccess({ ctrlData: { retrieveRateSummary: 'success',
                                              initAppRenderServices: ServiceCallStatus.READY }})]
      ),
      catchError(err => of(fromAppActions.RetrievePIRateSummaryFail({ respErr: err, ctrlData: { RetrievePIRateSummaryFail: 'failed' } }))
      )
    ))));

  /**
   * SEND EMAIL TO SCHEDUL AGENT
   */
  sendEmailToScheduleAgent$ = createEffect(() => this.actions$.pipe(ofType(fromAppActions.SendEmailToScheduleAgent),
    mergeMap((action) =>
      this.dataService.sendEmailToScheduleAgent$(action.reqPayload)
    )
  ), {dispatch : false}
  );

  // { respData: response }
  populateSubmitYourInfoDataForStore(req, resp) {
    let result = null;
    const reqObjCopy = JSON.parse(JSON.stringify(req));
    result = Object.assign({}, reqObjCopy, resp);
    result.customer.address = resp.address;
    /** For HOme Submit Yourinfo - Communication to be populated in the expected format to aligm refresh/retrieve scenario */
    if (result.customer.communication && Object.keys(result.customer.communication).length !== 0) {
      result.customer.communication.phoneNumber = result.customer.communication.phoneNumber.phoneNumber;
      if (result.customer.communication.emailId) {
        result.customer.communication.emailAddress = result.customer.communication.emailId.emailAddress;
      }
    }
    delete result.address;
    return result;
  }

  populateErrorForRQ(err) {
    return { service: 'RetrieveQuote', ...err };
  }

  saveActions$ = createEffect(() => this.actions$.pipe(ofType(fromAppActions.SaveActions),
    mergeMap(action =>
      this.dataService.invokeSaveActionsService(action.reqPayload).pipe(
        map(() => fromAppActions.SaveActionsSuccess({ controlData: { SaveActions: 'success' } }),
          catchError(() => of(fromAppActions.SaveActionsFail({ controlData: { SaveActions: 'fail' } })))
        )
      )
    )
  ));
}
