import { Component, OnInit, Input, ViewChild, AfterViewInit, AfterContentChecked } from '@angular/core';
import { InfoDialogComponent } from '../dialog';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Subscription } from 'rxjs';

@Component({
  selector: 'lib-coverage-panel',
  templateUrl: './coverage-panel.component.html',
  styleUrls: ['./coverage-panel.component.css']
})
export class CoveragePanelComponent implements OnInit, AfterViewInit, AfterContentChecked {

  @ViewChild('covPanel') panel: MatExpansionPanel;
  @Input()
  params: any;
  infoDialogComponent: InfoDialogComponent = new InfoDialogComponent(this.dialog);
  mdCoverageControl: any;
  gtmPrefix: any;
  gtmTag: any;
  private panelSub: Subscription;
  strikeOut = false;
  showNCPhyDamageIndDialogflag = false;
  stateNC = 'NC';
  falseInd = 'N';
  personalInjuryProtectionNoFault = 'Personal Injury Protection/No Fault Coverage';
  rideShareAndFoodDelivery = 'Rideshare and Food Delivery';
  // US446195: Display UMBI stacking and UIMBI stacking coverages for PA state - flex
  umbiStacking = '13129';
  statePA = 'PA';
  stateVA = 'VA';
  uninsuredUnderInsuredCodeForVA = 'UMUIM';
  vehicleSelectedByVin = false;
  constructor(
    private dialog: MatDialog,
  ) { }


  ngAfterContentChecked() {
    this.params.coverageItems.forEach((coverageItem, index) => {
      if (coverageItem.covgAmountStrikeOut === true) {
        this.strikeOut = true;
      }
    });
  }

  ngOnInit() {
    // US145574: ADA- Auto Quote- Combo box for financed and Leased vehicles are not announced as expected in browsers and devices
    this.params.coverageItems?.forEach((coverageItem) => {
      const value = coverageItem.coverageItemsDropdown.formGroup.value[coverageItem.cvgCode];
      coverageItem.coverageItemsDropdown.values.forEach((valuesEach) => {
        if (valuesEach.value === value) {
          const label = valuesEach.label;
          coverageItem.coverageItemsDropdown.ariaLabel = coverageItem.coverageItemsDropdown.ariaLabel + label;
        }
      });
   });
    this.mdCoverageControl = this.params.mdCoverages ? this.params.mdCoverages.formGroup.controls[`mdCoverage`] : '';
    /**
     * US197813: EE MVP (NC) - Auto product requirements of a display of the Quote page
     * when vehicle is ineligible for physical damage coverages
     */
    this.showNCPhyDamageIndDialogflag = this.physicalDamageInd();
    this.gtmPrefix = this.params.gtmTag;
    if (this.params.stateCode === 'MD') {
      this.GTMMDAdder();
    }
    this.vehicleSelectedByVin = this.params.vehicleVin ? true : false;
    /** Subscribing the change of the  VinVehicle Object in VehicleState */
  }
  GTMMDAdder() {
    if (this.params.mdCoverages) {
      this.params.mdCoverages.values.forEach(value => {
        value.gtmTag = `FFQ_Auto_${this.params.isBWQuote}Quote_UM_enhancedUIM${value.label}_dropdown`;
      });
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.panel.expanded) {
        this.gtmTag = this.gtmPrefix + 'covcollapse_button';
      } else {
        this.gtmTag = this.gtmPrefix + 'covexpand_button';
      }

      if (this.panel.expandedChange) {
        this.panelSub = this.panel.expandedChange.subscribe((state) => {
          if (state) {
            this.gtmTag = this.gtmPrefix + 'covcollapse_button';
          } else {
            this.gtmTag = this.gtmPrefix + 'covexpand_button';
          }
        });
      }
    });
  }

  showDialogInfo(infoText) {
    // US252434 - Help text for Rideshare and Food Delivery coverage
    // US83427-EE MVP (KY) - Auto product Help texts for  PIP coverages requirements for FFQ
    this.infoDialogComponent.openDialog(infoText.modalHeader ? infoText.modalHeader : infoText, infoText.modalBody ?
      infoText.modalBody : infoText, {
        styleClass:
          (infoText.modalHeader === this.personalInjuryProtectionNoFault || infoText.modalHeader === this.rideShareAndFoodDelivery ?
            'ffq-info-dialog-pip' : ''), buttonName: '', buttonGTM: `FFQ_Auto_${this.params.isBWQuote}Quote_modalclose_button`
    });
  }

  getValueForDisplayCode(coverageItem) {

    const dispayCode = coverageItem.displayCode;
    const valuesArray = coverageItem.coverageItemsDropdown.values;
    const displayValues: any = valuesArray.filter(elementItem => elementItem.value === dispayCode);
    if (displayValues[0]) {
      return displayValues[0].label;
    } else {
      return dispayCode;
    }
  }

  physicalDamageInd(): boolean {
    let phyDmg = false;
    if (this.params.stateCode && this.params.stateCode === this.stateNC) {
      if (this.params.physicalDamageIndicator && this.params.physicalDamageIndicator === this.falseInd) {
        phyDmg = true;
      }
    }
    return phyDmg;
  }
}
