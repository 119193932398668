/**
 * Vehicle state rule model
 */
export class VehicleStateRuleModel {
    /**
     * Determines whether purchase date view required is
     */
    isPurchaseDateViewRequired: boolean;
    /**
     * Determines whether ride share view required is
     */
    isRideShareViewRequired: boolean;
    /**
     * Determines whether park over night view required is
     */
    isParkOverNightViewRequired: boolean;
    /**
     * Determines whether state restrict two buissiness vehicle is
     */
    isStateRestrictTwoBuissinessVehicle: boolean;
    /**
     * Determines whether vehicle a is
     */
    isVehicleA: boolean;
    /**
     * Determines whether vehicle b is
     */
    isVehicleB: boolean;
    /**
     * Determines whether vehicle c is
     */
    isVehicleC: boolean;
    /**
     * Determines whether vehicle d is
     */
    isVehicleD: boolean;
    /**
     * Determines whether vehicle e is
     */
    isVehicleE: boolean;
    /**
     * Determines whether vehicle f is
     */
    isVehicleF: boolean;
    /**
     * Determines whether vehicle g is
     */
    isVehicleG: boolean;
    /**
     * Determines whether isGWImpl is true
     */
    isGWImpl: boolean;
    /**
     * Determines whether isRideShareCvgImpl is true
     */
    isRideShareCvgImpl: boolean;

    /**
     * Determines whether caauto class plan impl is
     */
    isCAAutoClassPlanImpl: boolean;

    /**
     * Determines whether fa three impl is
     */
    isFaThreeImpl: boolean;

}

export class DriverStateRuleModel {
    /**
     * Determines whether hide spouse occupation is
     */
    isHideSpouseOccupation: boolean;
    /**
     * Determines whether skip occupation is
     */
    isSkipOccupation: boolean;
    /**
     * Determines whether affinity implemented is-Validation for Occupation , From Project Code status
     */
    isAffinityImplemented: boolean;

    /**
     * Determines whether prior reason qn not required is // To Hide the Form Field for not having prior insurance reason
     */
    isPriorReasonQnNotRequired: boolean;

    /**
     * Determines whether military deployment qn required is - To display additional field for Miliatry Deployment question
     */
    isMilitaryDeploymentQnRequired: boolean;

    /**
     * Min age licence of driver state rule model
     */
    minAgeLicence: number;
    /**
     * Max age licence of driver state rule model
     */
    maxAgeLicence: number;
    /**
     * Determines whether non-specified gender option is available
     */
    isGenderXImpl: boolean;

    // FIELDS FOR ADDITIONAL INFO SCREENS

    /**
     * Determines whether mailing address required for auto is
     */
    isMailingAddressRequiredForAuto: boolean;

    /**
     * Determines whether bwimplemented is
     */
    isBWImplemented: boolean;

    /**
     * Determines whether auto prefill enabled is
     */
    isAutoPrefillEnabled: boolean;
    // Determines whether different disclaimer content to be displayed

    isBWTextDisclaimerTextStates: boolean;

    /**
     * Year accident is used in the label for Driver Accident/Violation Question
     */
    yearAccident: string;

    /**
     * Determines whether injury question has
     */
    hasInjuryQuestion: string;
    /**
     * Determines whether financial filing required
     */
    isFinanceFilling: boolean;

    isFDLAllowed: boolean;
    /**
     * Determines whether distant student discount eligible
     */
    isDistantStudentDiscountEligibility: boolean;
    /**
     * Determines whether principal PIP eligible
     */
    isPrincipalPIP: boolean;
    /**
     * Determines whether Commuter to NJ NY
     */
    isCommutersNJNY: boolean;
    /**
     * Determines whether Commuter to NY
     */
    isCommutersNY: boolean;
    /**
     * Determines whether Commuter to DC
     */
    isCommutersDC: boolean;
    /**
     * Determines whether driver had personal injury
     */
    isPersonalInjuryLoss: boolean;
    /**
     * Year Safety Course of driver state rule model
     */
    YearSafetyCourse: number;
    /**
     * Determines whether driver had safety course
     */
    IsAgeSafetyCourseFiftyFive: boolean;
    /**
     * Determines whether driver had safety course
     */
    IsAgeSafetyCourseSixty: boolean;
    /**
     * Determines whether driver had safety course
     */
    IsAgeSafetyCourseAlways: boolean;

    /**
     * Determines whether marital status civil union supported
     */
    IscivilUnionPartnerState: boolean;
    /**
     * Determines whether distant student discount eligible
     */
    isEEDistantStudentDiscountEligibility: boolean;
    /**
     * Determines whether active military discount is eligible
     */
    isActiveMilitaryEnabled: boolean;
    /**
     * Determines whether distant student discount eligible
     */
    isContinueousInsuranceImpl: boolean;
    /**
     * Determines whether driving exp impl is
     */
    isDrivingExp: boolean;
    /**
     * Determines whether Gender Non Binary enabled
     */
    isGenderNonBinaryEnabled: boolean;
    /**
     * Determines whether driving exp impl is
     */
    isLicenseInfo: boolean;

    /**
     * Determines whether accidentFree Enabled is
     */
    isAccidentFreeEnabled: boolean;
    /**
     * Determines whether mature Driver Enabled is
     */
    isMatureDriverEnabled: boolean;
    /**
     * Determines whether DNQ State is
     */
    isDNQState: boolean;
    /**
     * Determines whether DNQ Privacy Policy Enabled is
     */
    isDNQPrivacyPolicyEnabled: boolean;
    /**
     * Determines whether DNQ Summary Of Rights Enabled is
     */
    isDNQSummaryOfRightsEnabled: boolean;
    /**
     * Determines whether DNQ SummaryOfYourRights Disclaimer Enabled is
     */
    isDNQSummaryOfYourRightsText: boolean;

}
/**
 * Your info state rule model
 */
export class YourInfoStateRuleModel {
    /**
     * Determines whether auto prefill enabled is
     */
    isAutoPrefillEnabled: boolean;
    /**
     * Determines whether gender x isp
     */
    /**
     * Determines whether gender x is
     */
    isGenderX: boolean;
    /**
     * Min age licence of driver state rule model
     */
    minAgeLicence: number;
    /**
     * Max age licence of driver state rule model
     */
    maxAgeLicence: number;
    yourInfoState: string;
    alreadyAutoInsuranceViewRequired: boolean;
    /**
     * Subscription agreement of yourinfo state rule model
     */
    subscriptionAgreementRequired: boolean;
    /**
     * GW Rollout 3 states state rule model
     */
    isGWRollout3States: boolean;
    /**
     * Flex Rollout states state rule model
     */
    isFlexRolloutStates: boolean;
}
/**
 * Discount state rule model
 */
export class DiscountStateRuleModel {
    /**
     * Determines whether PolicyPaymentFrequency is
     */
    isPolicyPaymentFrequency: boolean;
    /**
     * Determines whether OwnOrRent is
     */
    isOwnOrRentQuestion: boolean;
    /**
     * Determines whether to showDiscountPNIQuestion
     */
    isDiscountPNIQuestion: boolean;
    /**
     * Determines whether to showHouseholdQues
     */
    isHouseholdQues: boolean;
    /**
     * Determines whether to show HouseholdHealthInsurance
     */
    isHouseholdHealthInsurance: boolean;
    /**
     * Determines whether to show DisabilityCoverage
     */
    isDisabilityCoverage: boolean;
    /**
     * Determines whether it is FSPAstates
     */
    isFSPAstates: boolean;

    /**
     * Determines whether gwimpl is
     */
    isGWImpl: boolean;

    /**
     * Determines whether bwimplemented is
     */
    isBWImplemented: boolean;

    /**
     * Determines whether eftimpl is
     */
    isEFTImpl: boolean;

}

/**
 * Quote state rule model
 */
export class QuoteStateRuleModel {

    /**
     * Determines whether household ques is
     */
    isHouseholdQues: boolean;

    /**
     * Determines whether discount pniquestion is
     */
    isDiscountPNIQuestion: boolean;

    /**
     * Determines whether bwimplemented is
     */
    isBWImplemented: boolean;

    /**
     * Determines whether gwimpl is
     */
    isGWImpl: boolean;

    /**
     * Determines whether ride share cvg impl is
     */
    isRideShareCvgImpl: boolean;

    /**
     * Determines whether custom quote impl is
     */
    isCustomQuoteImpl: boolean;
    /**
     * Determines whether Text disclaimer is based on state code for EE
     */
    isEETextDisclaimerTextStates: boolean;
    /**
     * Determines whether isHQMEnabled is
     */
    isHQMEnabled: boolean;
    /**
     * Determines whether eftimpl is
     */
    isEEImpl: boolean;

    isPolicyFeeEnabled: boolean;
    /**
     * Determines whether PolicyPerksImpl is
     */
    isPolicyPerksImpl: boolean;

    isHvqRate: boolean;
}
export class RideShareStateRuleModel {
    /**
     * Determines whether state has rideshare Assignment
     */
    isRideShareDriverCvgImpl: boolean;

}
export class DriverAssignmentStateRuleModel {
    /**
     * Determines whether state has driver assignment
     */
    IsDriverAssignment: boolean;
}
export class AssignVehicleDriverStateRuleModel {
    /**
     * Determines whether state has driver assignment
     */
    isAssignVehicleDriver: boolean;
}
export class SignalBannerStateRuleModel {
    /**
     * Determines whether state has Signal banner implemented
     */
    isSignalBannerImpl: boolean;
    /**
     * Determines whether state has different Signal banner popup content
     */
    isSignalBannerPopupContentChange: boolean;
    /**
     * Determines whether state has Signal banner implemented
     */
    isSignalBannerDiscountImpl: boolean;
}
