import { Injectable, EventEmitter } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { SaveAndFinishLaterObject } from '@ffq-app-auto/drivers';
import { AppStoreService } from '@ffq-app-store/app.store.service';
import { HelperService } from '@ffq-app-shared/services/helper.service';
import { DataService } from '@ffq-app-core/services/data.service';




@Injectable({
  providedIn: 'root'
})
export class NavigationDrawerService {
  showHideEvent: EventEmitter<any> = new EventEmitter();
  stepStatusChangeEvent: EventEmitter<any> = new EventEmitter();
  private sidenav: MatSidenav;
  private appData: any;
  private appControlData: any;
  constructor(private appStoreService: AppStoreService, private dataService: DataService) {
    this.appStoreService.appData$.subscribe((data) => {
      this.appData = data;
    });
    this.appStoreService.appControlData$.subscribe((data) => {
      this.appControlData = data;
    });
  }

  public setSidenav(sidenav: MatSidenav) {
    this.sidenav = sidenav;
  }

  public isSideNavOpened() {
    if (this.sidenav && this.sidenav.opened) {
      return true;
    } else {
      return false;
    }
  }

  public showDrawer() {
    // this.expandSideStrip();
    this.showHideEvent.emit('showSidePanel');
    if (this.sidenav) {
    this.sidenav.open();
    // this.showHideEvent.emit('show');
    }
  }


  isLargeScreen() {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (width > 960) {
      return true;
    } else {
      return false;
    }
  }


  public showSidePanel() {
    this.showHideEvent.emit('showSidePanel');
    if (this.isLargeScreen() && this.sidenav) {
      this.sidenav.open();
    }
  }

  public showAndExpandDrawer() {
    this.expandSideStrip();

    this.sidenav.close();

    const root = this;
    setTimeout(() => {
      root.sidenav.open();
    }, 50);
  }

  public hideDrawer() {
    if (this.sidenav) {
    this.sidenav.close();
    this.showHideEvent.emit('hide');
    }
  }

  public hideEverything() {
    if (this.sidenav) {
    this.sidenav.close();
    const hideObj = 'hideEverything';
    this.showHideEvent.emit(hideObj);
    }
  }

  public showHeader() {
    // this.sidenav.close();
    this.showHideEvent.emit('showHeader');
  }

  public hideHeader() {
    // this.sidenav.close();
    this.showHideEvent.emit('hideHeader');
  }

  public toggleDrawer() {
    if (this.sidenav) {
    this.sidenav.toggle();
    // this.showHideEvent.emit('toggle');
    }
  }

  public toggleSidePanelStrip() {
    this.showHideEvent.emit('toggleSidePanelStrip');
  }

  public expandSideStrip() {
    this.showHideEvent.emit('expandSideStrip');
  }

  public collapseSidePanel() {
    this.showHideEvent.emit('collapseSidePanel');
  }

  public showQuoteNumber() {
    this.showHideEvent.emit('showQuoteNumber');
  }

  public setCurrentStep(stepNumber: number) {
    this.stepStatusChangeEvent.emit(stepNumber);
  }

  createRequestPayload(emailAddress) {
    const saveAndFinishLaterObject = {} as SaveAndFinishLaterObject;
    saveAndFinishLaterObject.quoteNumber = this.appData.quoteNumber;
    saveAndFinishLaterObject.emailAddress = emailAddress ? emailAddress : this.appData.customerData.customer.communication.emailAddress;
    saveAndFinishLaterObject.lastVisitedPage = this.appControlData.lastVisitedPage;
    saveAndFinishLaterObject.pgLastVisitedPage = this.dataService.createPgLastVistedPageObject();
    return saveAndFinishLaterObject;
  }
}
