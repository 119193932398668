import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

/*TODO - Import this using Index.ts?  */
import * as AppState from './app.state';
import * as AppSelector from './app.selectors';
import * as fromAppActions from './app.actions';
import { Customer, SaveActionsRequest, ScheduleAgentAppointmentRequest } from '@ffq-app-shared/model/common.data.model';


@Injectable({
  providedIn: 'root'
})
export class AppStoreService {

  /**
   * App data$ of app store service
   */
  appData$ = this.appStore.select(AppSelector.getAppData);

  getRefreshFlag$ = this.appStore.select(AppSelector.getRefreshFlag);
  getRefreshFlagBusiness$ = this.appStore.select(AppSelector.getRefreshFlagBusiness);
  getAppSelector$ =  this.appStore.select(AppSelector.getAppSelector);

  /**
   * App control data$ of app store service
   */
  appControlData$ = this.appStore.select(AppSelector.getAppControlData);
  getSegmentEvent$ = this.appStore.select(AppSelector.getSegmentEvent);

  /**
   * App data zip$ of app store service
   */
  appDataZip$ = this.appStore.select(AppSelector.getAppDataZip);

  /**
   * App error$ of app store service
   */
  appError$ = this.appStore.select(AppSelector.getAppError);

  /**
   * App vin look up$ of app store service
   */
  // appVinLookUp$ = this.appStore.select(AppSelector.getAppVinLookUp);

  /**
   * Get app control values$ of app store service
   */
  getAppControlValues$ = this.appStore.select(AppSelector.getAppControlValues);

  /**
   * Get app token info$ of app store service
   */
  getAppTokenInfo$ = this.appStore.select(AppSelector.getAppTokenInfo);

  /**
   * Get encrypted data$ of app store service
   */
  getEncryptedData$ = this.appStore.select(AppSelector.getEncryptedData);

  /**
   * App customer data$ of app store service
   */
  appCustomerData$ = this.appStore.select(AppSelector.getAppCustomerData);

  /**
   * US376312 Customer communication data of app store service
   */
  getCommunicationData$ = this.appStore.select(AppSelector.getCommunicationData);

  /**
   * Existing customer data$ of app store service
   */
  getExistingCustomerData$ = this.appStore.select(AppSelector.getExistingCustomerData);

  /**
   * App customer data$ of app store service
   */
  appCustomerCityZipData$ = this.appStore.select(AppSelector.getCustomerCityZipData);

  /**
   * Get knockout response$ of app store service
   */
  getMDKnockoutInd$ = this.appStore.select(AppSelector.getMDKnockOutIndicator);

  /**
   * App source indicator data$ of app store service
   */
  sourceIndicatorData$ = this.appStore.select(AppSelector.getSourceIndicator);

  /**
   * App spouse customer data$ of app store service
   */
  appSpouseCustomerData$ = this.appStore.select(AppSelector.getSpouseCustomerData);

  /**
   * App data agent$ of app store service
   */
  appDataAgent$ = this.appStore.select(AppSelector.getAppDataAgent);


  /**
   * Get project code status$ of app store service
   */
  getProjectCodeStatus$ = this.appStore.select(AppSelector.getProjectCodeStatus);

  /**
   * Get landing state code$ of app store service
   */
  getLandingStateCode$ = this.appStore.select(AppSelector.getLandingStateCode);

  /**
   * Get landing zipcode$ of app store service
   */
  getLandingZipCode$ = this.appStore.select(AppSelector.getLandingZipCode);

  /**
   * Get validate zip$ of app store service
   */
  getValidateZip$ = this.appStore.select(AppSelector.getValidateZip);

  /**
   * Get App ControlData$ of app store service
   */
  getAppControlData$ = this.appStore.select(AppSelector.getAppControlData);

  /**
   * Get navigation data$ of app store service
   */
  getNavigationData$ = this.appStore.select(AppSelector.getNavigationData);

  /**
   * Get retrieve data status of app store service
   */
  getRetrieveDataStatus$ = this.appStore.select(AppSelector.getRetrieveDataStatus);


  /**
   * Get landing state description$ of app store service
   */
  getLandingStateDescription$ = this.appStore.select(AppSelector.getLandingStateDescription);

  /**
   * Save and finish later ctrl data$ of app store service
   */
  saveAndFinishLaterCtrlData$ = this.appStore.select(AppSelector.getSaveAndFinishLaterData);

  /**
   * Get existing lobs$ of app store service
   */
  getExistingLobs$ = this.appStore.select(AppSelector.getExistingLobs);
  /**
   * Get validate email$ of app store service
   */
  getValidateEmail$ = this.appStore.select(AppSelector.getValidateEmail);
  getFormEditedStatus$ = this.appStore.select(AppSelector.getFormEditedStatus);

  getSubmitYourInfoStatus$ = this.appStore.select(AppSelector.getSubmitYourInfoStatusHome);

  /** Get the LOB populated in Store */
  getLob$ = this.appStore.select(AppSelector.getLob);

  /** Get the Quoted LOB populated in Store */
  getQuotedLob$ = this.appStore.select(AppSelector.getQuotedLob);

  yourInfoLoadData$ = this.appStore.select(AppSelector.getCustomerData);

  getRedirectionFlow$ = this.appStore.select(AppSelector.getRedirectionFlow);

  getFwsToFFQRedirect$ = this.appStore.select(AppSelector.getFwsToFFQRedirect);

  getIsBWQuote$ = this.appStore.select(AppSelector.getIsBWQuote);

  getPniInformation$ = this.appStore.select(AppSelector.getPniInformation);

  // US372526 Added method for setting spouse occupation
  getSpouseOccupation$ = this.appStore.select(AppSelector.getSpouseOccupation);

  isBusinessHour$ = this.appStore.select(AppSelector.isBusinessHour);

  quoteNumber$ = this.appStore.select(AppSelector.getQuoteNumber);

  getLiveChatStatus$ = this.appStore.select(AppSelector.getLiveChatStatus);

  chatStatus$ = this.appStore.select(AppSelector.chatStatus);
  getDriverModuleEditedStatus$ = this.appStore.select(AppSelector.getDriverModuleEditedStatus);
  getVehicleModuleEditedStatus$ = this.appStore.select(AppSelector.getVehicleModuleEditedStatus);
  getVehicleDriverModuleEditedStatus$ = this.appStore.select(AppSelector.getVehicleorDriverModuleEditedStatus);
  // Update when Policy start date changes affecting the Drivingxp
  getPolicyStartDateChangesStatus$ = this.appStore.select(AppSelector.getPolicyStartDateChangedStatus);
  /**
   * Get package selected$ of app store service
   */
  getPackageSelected$ = this.appStore.select(AppSelector.getQuotePackageSelected);

  /**
   * Get rqservice status of app store service
   */
  getRQControlData$ = this.appStore.select(AppSelector.getRQControlData);

  /**
   * Get pi service status of app store service
   */
  getPIControlData$ = this.appStore.select(AppSelector.getPIControlData);

  /**
   * Get default rate quote called$ of app store service
   */
  getDefaultRateQuoteCalled$ = this.appStore.select(AppSelector.getDefaultRateQuoteCalled);

  /** Vehicle Count */
  getTotalVehicleCount$ = this.appStore.select(AppSelector.getTotalVehicleCount);
  /** Driver Count */
  getTotalDriverCount$ = this.appStore.select(AppSelector.getTotalDriverCount);

  /**
   * Get last visited page$ of app store service
   */
  getLastVisitedPage$ = this.appStore.select(AppSelector.getLastVisitedPage);
  /**
   * Get current page$ of app store service
   */
  getCurrentPageForSB$ = this.appStore.select(AppSelector.getCurrentPageForSB);

  getSignalDiscountSelected$ = this.appStore.select(AppSelector.getSignalDiscountSelected);

  getSignalDiscountClicked$ = this.appStore.select(AppSelector.getSignalDiscountClicked);

  getIsCoveragesUpdated$ = this.appStore.select(AppSelector.getIsCoveragesUpdated);

  getChangeZipStatus$ = this.appStore.select(AppSelector.getChangeZipStatus);

  getUpdatedCityZipData$ = this.appStore.select(AppSelector.getUpdatedCityZipData);

  /* Get bwPopUpSkipped from app store */
  getBwPopUpSkipped$ = this.appStore.select(AppSelector.getBwPopUpSkipped);

  getIsHomeInfoDisabled$ = this.appStore.select(AppSelector.getIsHomeInfoDisabled);


  /** Additional Page Visit Status */
  getAdditionalPageStatus$ = this.appStore.select(AppSelector.getAdditionalPageStatus);

  getBackDoorCustomFlag$ = this.appStore.select(AppSelector.getBackDoorCustomFlag);

  /** Get auto renters loader is reuired or not */
  getRequiredAutoRentersLoader$ = this.appStore.select(AppSelector.getRequiredAutoRentersLoader);

  getCrossSellEncryptedData$ = this.appStore.select(AppSelector.getCrossSellEncryptData);
  getUpdatedZipTownList$ = this.appStore.select(AppSelector.getUpdatedZipTownList);
  appCustomerTownZipData$ = this.appStore.select(AppSelector.getCustomerTownZipData);
  getPhoneNumberFromSrc$ = this.appStore.select(AppSelector.getPhoneNumberFromSrc);

  /** Get init app render services$ of app store service */
  getInitAppRenderServices$ = this.appStore.select(AppSelector.getInitAppRenderServices);
  getLandingSourceFromSrc$ = this.appStore.select(AppSelector.getLandingSourceFromSrc);

  /** Get phone number service status$ of app store service */
  getPhoneNumberServiceStatus$ = this.appStore.select(AppSelector.getPhoneNumberServiceStatus);
  getStaticContent$ = this.appStore.select(AppSelector.getStaticContent);

  /** Determines whether aemcontent call is needed */
  isAEMContentCallNeeded$ = this.appStore.select(AppSelector.isAEMContentCallNeeded);

  /** Get aemcontent service call status$ of app store service */
  getAEMContentServiceStatus$ = this.appStore.select(AppSelector.getStaticContentServiceStatus);

  /** Get Flow Data For Quote of app store service */
  getFlowDataForQuote$ = this.appStore.select(AppSelector.getFlowDataForQuote);

  /** Get cvg updation required$ of app store service */
  getCvgUpdationRequired$ = this.appStore.select(AppSelector.getCvgUpdationRequired);

  /**
   * Get app lms redirect values$ of app store service
   */
  getLmsRedirectData$ = this.appStore.select(AppSelector.getLmsRedirectData);

  getPpcSrcAvailabilityStatus$ = this.appStore.select(AppSelector.getPpcSrcAvailabilityStatus);
  /** US322358 : FFQ change to track ADPF VS NON ADPF */
  getPartnerADPFFlow$ = this.appStore.select(AppSelector.getPartnerADPFFlow);

  // to get promo id and campaign id
  getPromoIdAndCid$ = this.appStore.select(AppSelector.getPromoIdAndCid);

  /**
   * Get last visited step of app store service
   */
  getLastVisitedStep$ = this.appStore.select(AppSelector.getLastVisitedStep);
  /**
   * Get segment quote started event info of app store service
   */
  getQuoteStartedEventStatus$ = this.appStore.select(AppSelector.getQuoteStartedEventStatus);

  /**
   * Get utm parameters of app store service
   */
  getUtmParameters$ = this.appStore.select(AppSelector.getUtmParameters);

  /**
   * Get campaign parameters of app store service
   */
  getCampaignParameters$ = this.appStore.select(AppSelector.getCampaignParameters);

  /**
   * Get multiquote response data from Retrieve service response
   */

  getMultiQuoteData$ = this.appStore.select(AppSelector.getMultiQuoteData);

  getBWCompanyCode$ = this.appStore.select(AppSelector.getBWCompanyCode);

  /**
   * Get customer info of app store service for test user check
   */
  getTestUserCheckProperties$ = this.appStore.select(AppSelector.getTestUserCheckProperties);

  /**
   * Get response data from Retrieve service response
   */

  getRetrieveQuoteRedirectionData$ = this.appStore.select(AppSelector.getRetrieveQuoteRedirectionData);

  // get  prefill status  for retrieve quote
  getRQPrefillStatus$ = this.appStore.select(AppSelector.getRQPrefillStatus);

  // get  prefill status  for retrieve quote PI flow
  getRQPrefillStatusForPI$ = this.appStore.select(AppSelector.getRQPrefillStatusForPI);

  /**
   * Get connected car status of auto store service
   */
  getConnectedCarStatus$ = this.appStore.select(AppSelector.getConnectedCarStatus);

  /**
   * Gets nav data of current module$
   * @param moduleName currentModule
   * @returns status of module
   */
  getNavDataOfCurrentModule$(moduleName) {
    return this.appStore.select(AppSelector.getNavDataOfCurrentModule, { moduleName });
  }

  /**
   * Creates an instance of app store service.
   * @param appStore  AppStore
   */
  constructor(
    private appStore: Store<AppState.AppState>
  ) { }

  /**
   * Starts quote
   * @param reqPayload  Request Object
   */
  startQuote(reqPayload: any) {
    return this.appStore.dispatch(fromAppActions.StartQuote({ reqPayload }));
  }

  getSourcePhoneNumber(reqPayload: any) {
    return this.appStore.dispatch(fromAppActions.SourcePhoneNumberService({ reqPayload}));
  }

  /**
   * Retrives quote
   * @param reqPayload  Request Object
   */

  retriveQuote(reqPayload: any) {
    this.appStore.dispatch(fromAppActions.RetrieveQuote({ reqPayload }));
  }

  retrievePIRateSummary(reqPayload: any) {
    this.appStore.dispatch(fromAppActions.RetrievePIRateSummary({ reqPayload }));
  }

  /**
   * Gets project status code
   * @param reqPayload Date Object
   */
  getProjectStatusCode(reqPayload: any) {
    this.appStore.dispatch(fromAppActions.GetProjectCodeStatus({ reqPayload }));
  }


  /**
   * Validate zip code
   * @param reqPayload  Request Object
   */
  validateZipCode(reqPayload: any) {
    this.appStore.dispatch(fromAppActions.ValidateZipCode({ reqPayload }));
  }

  /**
   * Clear zip code validation result
   */
  clearZipValidationResult() {
    this.appStore.dispatch(fromAppActions.ClearZipValidationErrorMessage());
  }

  /**
   * Changes zipcode
   * @param reqPayload Request Object
   */
  changeZipCode(reqPayload: any) {
    this.appStore.dispatch(fromAppActions.ChangeZipCode({ reqPayload }));
  }
  /**
   * Changes formedited
   * @param reqPayload Request Object
   */
  updateFormEdited(reqPayload: any) {
    this.appStore.dispatch(fromAppActions.UpdateFormEdited({ reqPayload }));
  }
  /**
   * Changes vehicle added after quote
   * @param reqPayload Request Object
   */
  vehicleChangedAfterQuote(reqPayload: any) {
    this.appStore.dispatch(fromAppActions.VehicleChangedAfterQuote({ reqPayload }));
  }

  vehicleOrDriverChanged(reqPayload: boolean) {
    this.appStore.dispatch(fromAppActions.VehicleOrDriverChanged({ reqPayload }));
  }
  /**
   * Changes vehicle added after quote
   * @param reqPayload Request Object
   */
  updateGender(reqPayload: any) {
    this.appStore.dispatch(fromAppActions.UpdatePNIGender({ reqPayload }));
  }
  driverChangedAfterQuote(reqPayload: any) {
    this.appStore.dispatch(fromAppActions.DriverChangedAfterQuote({ reqPayload }));
  }
  policyStartDateChanged(reqPayload: any) {
    this.appStore.dispatch(fromAppActions.PolicyStartDateChanged({ reqPayload }));
  }
  /**
   * Updates navigation data
   * @param reqPayload updated status
   */
  updateAutoNavData(reqPayload: any) {
    this.appStore.dispatch(fromAppActions.UpdateAutoNavStatus({ reqPayload }));
  }

  /**
   * Updates the Navigation Status
   * @param reqPayload Updates Navigation Status
   */
  updateNavData(lob: string, reqPayload: any) {
    if (lob === 'H' || lob === 'R' || lob === 'C') {
      // Updating Home Nav Status
      this.appStore.dispatch(fromAppActions.UpdateHomeNavStatus({ reqPayload }));
    } else if (lob === 'B') {
      // Updating Business Nav Status
      this.appStore.dispatch(fromAppActions.UpdateBusinessNavStatus({ reqPayload }));
    } else if (lob === 'A') {
      // Updating Auto Nav Status
      this.appStore.dispatch(fromAppActions.UpdateAutoNavStatus({ reqPayload }));
    }
  }

  /**
   * Saves and finish later
   * @param reqPayload Request Object
   */
  saveAndFinishLater(reqPayload: any) {
    this.appStore.dispatch(fromAppActions.SaveAndFinishLater({ reqPayload }));
  }

  /**
   * Updates Retrieve Data Status
   * @param reqPayload updated status
   */
  updateRetrieveDataStatus(reqPayload: any) {
    this.appStore.dispatch(fromAppActions.UpdateRetrieveDataStatus({ reqPayload }));
  }

  /* getEncryptedCrossSellData
  * @param reqPayload  Request Object
  */
  getEncryptedCrossSellData(reqPayload: any) {
    return this.appStore.dispatch(fromAppActions.GetCrossSellEncryptedData({ reqPayload }));
  }
  /**
   * Validate email
   * @param reqPayload  Request Object
   */
  validateEmail(reqPayload: any) {
    this.appStore.dispatch(fromAppActions.ValidateEmail({ reqPayload }));
  }
  /**
   * Updates last visted page
   * @ param reqPayload
   */
  updateLastVistedPage(reqPayload: any) {
    this.appStore.dispatch(fromAppActions.UpdateLastVisitedPage({ reqPayload }));
  }

  /**
   * Updates is driver screen validated
   * @ param ctrlData
   */
  updateIsDriverScreenValidated(ctrlData: any) {
    this.appStore.dispatch(fromAppActions.ValidateDriverPage({ ctrlData }));
  }
  /**
   *  Sessions keep alive
   * @param reqPayload  Request Object
   */
  sessionKeepAlive(reqPayload: any) {
    this.appStore.dispatch(fromAppActions.SessionKeepAlive({ reqPayload }));
  }

  /**
   * Updates auto control data
   * @param ctrlData ControlData
   */
  updateAppControlData(ctrlData: any) {
    this.appStore.dispatch(fromAppActions.UpdateAppControlData({ ctrlData }));
  }

  /**
   * Updates control data with segment event
   * @param ctrlData ControlData
   */
  updateSegmentEvent(ctrlData: object): void {
    this.appStore.dispatch(fromAppActions.UpdateSegmentEvent({ ctrlData }));
  }
  /* Saves your info
  * @param reqPayload reqPayload
  */
  saveYourInfo(reqPayload: any) {
    this.appStore.dispatch(fromAppActions.SubmitYourInfo({ reqPayload }));
  }

  /**
   * Assigns agent
   * @param reqPayload reqPayload
   */
  assignAgent(reqPayload: any) {
    this.appStore.dispatch(fromAppActions.AssignAgent({ reqPayload }));
  }

// Update Prefill Flag in control data
updateLandingSrc(reqPayload: any) {
  return this.appStore.dispatch(fromAppActions.UpdateLandingSrc({ reqPayload }));
}

// Update Landing Source Code
updatePrefillFlag(ctrlData: any) {
  return this.appStore.dispatch(fromAppActions.UpdatePrefillFlag({ ctrlData }));
}

  startQuoteWithQuoteNumber(reqPayload: any) {
    return this.appStore.dispatch(fromAppActions.StartQuoteWithQuoteNumber({ reqPayload }));
  }

  // Update Cross Selll Status
  updateCrossSellStatus(ctrlData: any) {
    return this.appStore.dispatch(fromAppActions.UpdateCrossSellStatus({ ctrlData }));
  }

  // Update Flow Paramter
  updateRedirectionFlow(ctrlData: any) {
    return this.appStore.dispatch(fromAppActions.UpdateRedirectionFlow({ ctrlData }));
  }


  agentBusinessHour() {
    return this.appStore.dispatch(fromAppActions.AgentBusinessHour());
  }

  checkChatStatus() {
    return this.appStore.dispatch(fromAppActions.CheckChatStatus());
  }

  /** Update Vehicle Count */
  updateVehicleCount(ctrlData: any) {
    return this.appStore.dispatch(fromAppActions.UpdateVehicleCount({ ctrlData }));
  }

  /** Update Driver Count */
  updateDriverCount(ctrlData: any) {
    return this.appStore.dispatch(fromAppActions.UpdateDriverCount({ ctrlData }));
  }

  /** Update Driver Count */
  updateAdditionaPageStatus(ctrlData: any) {
    return this.appStore.dispatch(fromAppActions.AdditionalDetailsPageStatus({ ctrlData }));
  }

  /** BackDoor Custom Quote Impl Flag */
  backDoorCustomQuoteImplFlag(ctrlData: any) {
    return this.appStore.dispatch(fromAppActions.BackDoorCustomCodeImplementFlag({ ctrlData }));
  }

  /** Direct update of start Quote */
  updateStartQuoteDirectly(response, appReqPayload, ctrlData) {
    return this.appStore.dispatch(fromAppActions.StartQuoteSuccess({ respData: response, reqPayload: appReqPayload, ctrlData }));
  }

   /** BackDoor Custom Quote Impl Flag */
   updateRefreshFlag(ctrlData: any) {
    return this.appStore.dispatch(fromAppActions.UpdateRefreshFlag({ ctrlData }));
  }

  updateRootStoreDirectly(reqPayload: any) {
    return this.appStore.dispatch(fromAppActions.UpdateAppRootStoreDirectly({ reqPayload }));
  }

  /**
   * Updates app data
   * @param reqPayload reqPayload
   */
  updateAppData(reqPayload: any) {
    return this.appStore.dispatch(fromAppActions.UpdateAppData({reqPayload}));
  }

  /** calls AEM static content service   */
  getAEMStaticContent() {
    return this.appStore.dispatch(fromAppActions.StaticContentService());
  }

  getFetchTownList() {
    return this.appStore.dispatch(fromAppActions.FetchTownList());
  }

  findContent(pageName) {
    let staticJson;
    this.appStore.select(AppSelector.getStaticContent).subscribe((data: any) => {
      if (data) { staticJson = data; }
    });
    return staticJson && staticJson[pageName];
  }

  /**
   * Updates last visted step for segment tracking
   * @ param reqPayload
   */
  updateLastVistedStep(reqPayload: any) {
    this.appStore.dispatch(fromAppActions.UpdateLastVisitedStep({ reqPayload }));
  }

  updatePpcSrcAvailabilityStatus(ctrlData: any) {
    return this.appStore.dispatch(fromAppActions.UpdatePpcSrcAvailabilityStatus({ctrlData}));
  }

  updatePromoIdCid(reqPayload: any) {
    return this.appStore.dispatch(fromAppActions.UpdatePromoIdCid({ reqPayload }));
  }

  updateUtmParameters(reqPayload: any) {
    return this.appStore.dispatch(fromAppActions.UpdateUtmParameters({ reqPayload }));
  }

  updateCampaignParameters(reqPayload: any) {
    return this.appStore.dispatch(fromAppActions.UpdateCampaignParameters({ reqPayload }));
  }

  updateCustomerLastName(reqPayload: any) {
    return this.appStore.dispatch(fromAppActions.UpdateCustomerLastName({ reqPayload }));
  }

  updateCustomerEmail(reqPayload: any) {
    return this.appStore.dispatch(fromAppActions.UpdateCustomerEmail({ reqPayload }));
  }

  updateBWCompanyCode(reqPayload: any) {
    return this.appStore.dispatch(fromAppActions.UpdateBWCompanyCode({ reqPayload }));
  }

  // US376312: Change for updating communication data
  updateCommsFromApptPopup(respData: any): void {
    this.appStore.dispatch(fromAppActions.UpdateCommunicationDetailsOnSuccess({respData}));
  }
  updateCustomerDetailsFromApptPopup(respData: Customer): void {
    this.appStore.dispatch(fromAppActions.UpdateCustomerDetails({respData}));
  }
  // US373700: send email to schedule agent
  sendEmailToScheduleAgent(reqPayload: ScheduleAgentAppointmentRequest): void {
    this.appStore.dispatch(fromAppActions.SendEmailToScheduleAgent({ reqPayload }));
  }

  saveActions(reqPayload: SaveActionsRequest) {
    this.appStore.dispatch(fromAppActions.SaveActions({reqPayload}));
  }

}
