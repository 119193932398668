
	<div class="footer-links-container">


        <div class="footer-links-row">
            <div class="footer-links-icon">
                <img src="../../../../assets/images/link-contact-icon.png" alt="" /></div>
            <div *ngIf="showAsStrip==false"   class="footer-links-content">
                <div class="footer-links-item"><a href="http://www.farmers.com/contact-us/" target="_blank" aria-label="Contact us opens in new window" role="link" data-GTM="FFQ_Auto_sidepanel_contactus_link">Contact us</a>
                </div>
            </div>
        </div>

        <div class="footer-links-row">
            <div class="footer-links-icon"><img src="../../../../assets/images/link-icon.png" alt="" /></div>
            <div *ngIf="showAsStrip==false"   class="footer-links-content">
                <div class="footer-links-item">
                    <a href="https://www.farmers.com/disclaimer/terms-of-use/" target="_blank" aria-label="Terms of Use opens in new window" role ="link" data-GTM="FFQ_Auto_sidepanel_termsofuse_link">Terms of use</a>
                </div>
            </div>
        </div>

        <div class="footer-links-row">
            <div class="footer-links-icon"><img src="../../../../assets/images/link-icon.png" alt="" /></div>
            <div *ngIf="showAsStrip==false"   class="footer-links-content">
                <div class="footer-links-item">
                    <a href="https://www.farmers.com/privacy-statement/#donotsell" target="_blank" aria-label="Privacy Policy opens in new window" role="link" data-GTM="FFQ_Auto_sidepanel_privacypolicy_link">Do not sell or share my personal information</a>
                </div>
            </div>
        </div>

        <div class="footer-links-row" *ngIf="showNoticeOfInformation==true">
            <div class="footer-links-icon"><img src="../../../../assets/images/link-icon.png" alt="" /></div>
            <div *ngIf="showAsStrip==false"   class="footer-links-content">
                <div class="footer-links-item">
                    <a href="{{url}}" target="_blank" aria-label="Notice of Information practices opens in new window" role="link" data-GTM="FFQ_Auto_sidepanel_noticeinfoprac_link">Notice of Information practices</a>
                </div>
            </div>
        </div>



        <div class="footer-links-row">
            <div *ngIf="showAsStrip==false"   class="footer-links-content">
                <div class="footer-links-copyright">Copyright © {{currentYear}} Farmers Insurance Group
                </div>
            </div>
        </div>

        <div class="footer-links-row footer-links-cyber-trust" [style.visibility] = "showLogo() ? 'hidden' : 'visible' ">
            <div class="footer-links-icon" id= "DigiCertClickID_Z9bZfwjL" data-language="en" data-GTM="FFQ_Auto_sidepanel_digicertlogo_link">
                </div>
        </div>        
    </div>
