import { Component, ViewChild, OnDestroy, OnInit, AfterViewInit, HostListener, Inject, ElementRef, SecurityContext } from '@angular/core';
import { NavigationDrawerService, ServiceConstants } from './shared';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { PlatformLocation, DatePipe } from '@angular/common';
import { ModuleProgressStatus, RateQuoteProgressStatus } from '@ffq-app-shared/enums/flow.identifier.enum';
import { AppStoreService } from '@ffq-app-store/app.store.service';
import { HelperService } from '@ffq-app-shared/services/helper.service';
import { SessionTimeoutService } from './shared/services/session-timeout.service';
import { DataConstants } from '@ffq-app-shared';
import { Subscription } from 'rxjs';
import { ActionDialogComponent } from '@ffq-lib';
import { LibraryDataService } from '@ffq-lib/src/lib/core/services/library-data.service';
import { Router, Event, NavigationEnd, ActivatedRoute, NavigationStart } from '@angular/router';
import { SaveAndFinishLaterObject } from '@ffq-app-auto/drivers';
import { NavigationHelperService } from '@ffq-app-shared/services/navigation-helper.service';
// tslint:disable-next-line: max-line-length
import { SaveAndFinishOnTimeoutComponent } from '@ffq-app-shared/components/save-and-finish-on-timeout/save-and-finish-on-timeout.component';
import { DataLayerService } from '@ffq-app-core/services/data-layer.service';
import { take } from 'rxjs/operators';
import { SpinnerService } from '@ffq-lib/src/lib/spinner/spinner.service';
import { RideShareStateRuleModel, DriverAssignmentStateRuleModel,
  AssignVehicleDriverStateRuleModel } from '@ffq-app-shared/model/state.rule.model';
import { PremiumAmountObj, UtmParams, CampaignParams, ConnectedCarRequest } from '@ffq-app-shared/model/common.data.model';
import { StateRuleModelService } from '@ffq-app-shared/services';
import { NavigationStepsAutoEnum } from '@ffq-app-shared/enums/navigation-steps-auto.enum';
import { DOCUMENT } from '@angular/common';
import { AutoStoreService } from '@ffq-app-auto/store/auto.store.service';
import { QualtricsTimeoutService } from './shared/services/qualtrics-timeout.service';
import { FlowParamter } from '@ffq-app-shared/enums/flow.identifier.enum';
import { DriversStoreService } from '@ffq-app-auto/drivers/store/drivers.store.service';
import { LegacyRedirectionService } from './core';
import { DomSanitizer } from '@angular/platform-browser';
import { InfoDialogService } from '@ffq-lib/src/lib/dialog/info-dialog/info-dialog.service';
import { SegmentService } from '@ffq-app-shared/services/segment.service';
import { HelpModalOpenedProps } from '@ffq-app-shared/model/segment.data.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})


export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  title = 'ffq-app';
  events: string[] = [];
  /**
   * Modal data of about yourself component
   */
  modalData = DataConstants.MODAL_DATA;
  private showAsStrip = false;
  private showAsPanel = true;

  public openFlag = true;
  public matdrawerMode = 'side';
  public scrWidth: number;
  public isExpanded = false;

  public moduleProgressStatus = ModuleProgressStatus;
  /**
   * navigation Popup variables
   */
  actionDialogComponent = new ActionDialogComponent(this.dialog, this.libDataService);
  cancelLeftNavModalAction: any;
  public quoteNumber = null;
  public moduleName: any;
  public timeOutPopup = false;
  public dialogRef: any;
  scollValue;
  elementIndex;
  public dialogTitle: any;
  isSignalBannerDiscountImpl = false;
  isBWQuote = false;
  isConnectedCarImpl = false;
  isConnectedCarEligible = false;
  isFXImpl = false;
  isGWImpl = false;
  private sidenav: MatSidenav;
  retrieveIndicator: boolean;
  isRateSummaryScreen = true;
  bWQuote = false;
  /** US499784 : Digital Monetization Full Package Full Premium changes */
  isFullPackageFullPremiumImpl = false;
  secondRateCallStatus = false;
  public isInactivityTimeReached = false;
  isMigratedLandingEnabled: boolean;
  // @ViewChild('sidenav', {static: true}) public sidenav: MatSidenav;
  @ViewChild('sidenav') set content(content: MatSidenav) {
    if (content) { // initially setter gets called with undefined
      this.sidenav = content;
      this.navService.setSidenav(this.sidenav);
    }
  }

  switchInProgress = 'app-nav-content-fill';
  public stepsCompleted = 1;
  public currentStep = 1;
  public navigationLinks: Array<any> = [];
  edited: boolean;
  vehicleEdited = false;
  driverEdited = false;
  isVehicleOrDriverEdited = false;
  additionalPageStatus = null;
  private subscriptions: Subscription[] = [];
  private idleTimerSubscription: Subscription;
  private paramSubscription: Subscription = new Subscription();
  /**
   * Rideshare state rule model of add driver component
   */
  rideshareStateRuleModel: RideShareStateRuleModel;
  /**
   * Driver assignment state rule model of add driver component
   */
  driverassignmentStateRuleModel: DriverAssignmentStateRuleModel;
  /**
   * Assign vehicle driver state rule model of app component
   */
  assignVehicleDriverStateRuleModel: AssignVehicleDriverStateRuleModel;
  /**
   * Save and finish later object of save and finish component
   */
  saveAndFinishLaterObject = {} as SaveAndFinishLaterObject;
  selectedLob: string;
  private emailAddress: any;
  landingStateCode: string;
  hideHeader: boolean;
  // US176305: Display Quote Premium Banner
  displayQuotePremium: false;
  /**
   * premium Amoutt Obj for PI flow
   */
  premiumAmtObj: PremiumAmountObj;
  totalVehicleCount = 0;
  totalDriverCount = 0;
  routerSubscription: Subscription;
  browserRefresh = false;
  showPrintRouter = false;
  currentUrl: string;
  /**
   * rateSummaryRedirection of navigation drawer component
   */
  rateSummaryRedirection = false;
  readonly qSI = 'QSI';
  /**
   * Determines whether exp drv in
   */
  inExpDrv: boolean;
  /**
   * No of in exp drv of app component
   */
  noOfInExpDrv = 0;
  totalPrefillDrivers: Array<object>;
  constructor(
    private navService: NavigationDrawerService,
    private appStoreService: AppStoreService,
    private router: Router,
    private sessionTimeoutService: SessionTimeoutService,
    private navigationDrawerService: NavigationDrawerService,
    private helperService: HelperService,
    private location: PlatformLocation,
    private dialog: MatDialog,
    private libDataService: LibraryDataService,
    private stateRuleModel: StateRuleModelService,
    private route: ActivatedRoute,
    private navHelper: NavigationHelperService,
    private dataLayerService: DataLayerService,
    private spinnerService: SpinnerService,
    private legacyRedirectionService: LegacyRedirectionService,
    private datePipe: DatePipe,
    private autoStoreService: AutoStoreService,
    private driverStoreService: DriversStoreService,
    private domSanitizer: DomSanitizer,
    @Inject(DOCUMENT) private document: Document,
    private qualtricsTimeoutService: QualtricsTimeoutService,
    private infoService: InfoDialogService,
    private segmentService: SegmentService) {
    this.isMigratedLandingEnabled = ServiceConstants.IS_MIGRATED_LANDING_PAGE_ENABLED === 'Y';
    this.routerSubscription = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.browserRefresh = !router.navigated;
        if (this.browserRefresh) {
          this.appStoreService.updateRefreshFlag({ isRouterRefreshed: true });
        }
        /** US367786: brower back issue fix - start */
        if (this.isMigratedLandingEnabled) {
          const routerPath = event.url.split('?')[0];
          if ( routerPath === '/' && (event.navigationTrigger === 'popstate' ||
             (sessionStorage.getItem('isLegacyOrHQMQuote') === 'true'))) {
            this.historyForward();
          }
        }
        /** brower back issue fix - end */
      }

    });
    navService.stepStatusChangeEvent.subscribe(
      (newStepNumber) => {
        this.stepsCompleted = newStepNumber;
        this.currentStep = newStepNumber;
      }
    );

    navService.showHideEvent.subscribe(
      (sidePanelEvent) => {
        if (sidePanelEvent === 'expandSideStrip') {

          //  this.showAsStrip = false;
          //  this.showAsPanel = true;
        } else if (sidePanelEvent === 'collapseSidePanel') {

          //  this.showAsStrip = true;
          //  this.showAsPanel = false;

        } else if (sidePanelEvent === 'toggleSidePanelStrip') {

          this.showAsStrip = !this.showAsStrip;

          //  if(this.showAsStrip==true){
          //   this.showAsStrip = false;
          //    this.showAsPanel = true;

          // }else{
          //   this.showAsStrip = true;
          //    this.showAsPanel = false;

          // }

        }
      }
    );
  }
  /**
   * Browser forward
   */
   historyForward(): void {
    history.go(1);
  }

  ngOnInit(): void {
    this.persistStore();
    this.handleRefresh();

    // Loading Google Autocomplete Script
    this.loadGoogleAutoCompleteScript();

    // Open Spinner
    this.spinnerService.showLoader();
    // US241955- Help Modal Opened segment event
    this.subscriptions.push(this.infoService.getDialogStatus().subscribe(dialogInfo => {
      this.callSegmentTrackingForInfoDialog(dialogInfo);
    }));
    /**
     * Based on the Query paramer - Initial Store data is populated.
     */
    this.paramSubscription = this.route.queryParams.subscribe(params => {
      if (params.LOB) {
        // Selecting LOB based on Params
        this.selectedLob = params.LOB;
      }
      // check if BW quote for Qualtrics
      this.subscriptions.push(this.autoStoreService.getBrightLinedData$.subscribe((data: any) => {
        this.bWQuote = (data && data.autoCompanyCode && data.autoCompanyCode === 'B');
      }));

      /** Initial navigation  data is set only the initial time & not when FLow parameter is available */
      if (this.selectedLob && !params.Flow && !this.browserRefresh) {
        // Populating the initial store data
        this.navHelper.populateInitialNavigationData(this.selectedLob);
        // Unsubscribing - Once the Initial store is loaded. This need to happen only once based on query parameter
        this.paramSubscription.unsubscribe();
      } else if (params.Flow) {
        // Unsubscribing paramSubscription for keeping current navigation status properly based on store values
        this.paramSubscription.unsubscribe();
      }
      /** Update UTM parameters from Landing/Redirection into app store before router erases */
      if (params.utm_source || params.utm_campaign || params.utm_medium || params.utm_content || params.utm_term) {
        const utmParameters = {} as UtmParams;
        const utmParams = ['utm_source', 'utm_campaign', 'utm_medium', 'utm_content', 'utm_term'];
        utmParams.forEach((param) => {
          if (params[param]) {
            utmParameters[param] = params[param];
          }
        });
        this.appStoreService.updateUtmParameters({ utmParameters });
      }

      /** Update Campaign parameters from Landing/Redirection into app store before router erases */
      if (params.cid || params.clickkey || params.fbclid || params.gclid || params.gclsrc || params.mcid || params.promoid) {
        const campaignParameters = {} as CampaignParams;
        const campaignParams = ['cid', 'clickkey', 'fbclid', 'gclid', 'gclsrc', 'mcid', 'promoid'];
        campaignParams.forEach((param) => {
          if (params[param]) {
            campaignParameters[param] = params[param];
          }
        });
        this.appStoreService.updateCampaignParameters({ campaignParameters });
      }
    });
    this.rideshareStateRuleModel = this.stateRuleModel.getRideshareStateRule(this.landingStateCode);
    this.driverassignmentStateRuleModel = this.stateRuleModel.getDriverAssignmentStateRule(this.landingStateCode);
    this.assignVehicleDriverStateRuleModel = this.stateRuleModel.getAssignVehicleDriverStateRule(this.landingStateCode);
    this.navService.setSidenav(this.sidenav);
    this.subscriptions.push(this.appStoreService.appData$.subscribe(data => {
      this.quoteNumber = data ? data.quoteNumber : null;
    }));

    this.navService.showHideEvent.subscribe(
      (sidePanelEvent) => {
        if (sidePanelEvent === 'hideEverything') {
          this.hideHeader = true;
        } else if (sidePanelEvent === 'hideHeader') {
          this.hideHeader = true;
        } else if (sidePanelEvent === 'showHeader') {
          this.hideHeader = false;
        }
      }
    );

    this.subscriptions.push(this.appStoreService.appData$.subscribe((data) => {
      if (data && data.customerData && data.customerData.customer) {
        this.emailAddress = data.customerData.customer.communication.emailAddress;
        this.landingStateCode = data.landingStateCode;
      }
    }));

    /** get Flow parameter */
    this.subscriptions.push(this.appStoreService.getRedirectionFlow$.subscribe((data: any) => {
      this.retrieveIndicator = data === FlowParamter.RETRIEVE_QUOTE;
    }));

    this.routerEventSubscription();
    this.QualtricsTimerSubscription();
    this.IdleTimeoutSubcription();
    this.appStoreService.getNavigationData$.subscribe(data => {
      this.appStoreService.getLob$.pipe(take(1)).subscribe((lobInStore: any) => {
        this.selectedLob = lobInStore;
      });
      setTimeout(() => {
        if (this.selectedLob) {
          // navigationLinks - UI Links
          this.navigationLinks = this.navHelper.getClientNavigationLinks(this.selectedLob, data);
        }
      });
    });

    /** Subscription declared to update the total vehicle count */
    this.appStoreService.getTotalVehicleCount$.subscribe(totalVehicleCount => {
      this.totalVehicleCount = totalVehicleCount;
    });
     // US221089: Auto driver count mismatch
    this.subscriptions.push(this.autoStoreService.getPrefillDrivers$.subscribe(driverData => {
      this.totalPrefillDrivers = driverData;
    }));
    /** Subscription declared to update the total driver count */
    this.appStoreService.getTotalDriverCount$.subscribe(totalDriverCount => {
      this.totalDriverCount = totalDriverCount;
      // US221089: Auto driver count mismatch
      if (this.totalDriverCount && this.totalPrefillDrivers && this.totalPrefillDrivers.length &&
        this.totalDriverCount === this.totalPrefillDrivers.length) {
        this.hideAdditionalInfo();
      }
    });
    this.getCurrentRoute();
    // US176305: Display Quote Premium Banner
    this.appStoreService.appControlData$.subscribe((data) => {
      this.premiumAmtObj = data.quotePremiumAmount;
    }).unsubscribe();
  }

  getCurrentRoute() {
    /** Subscription to get current route */
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        /**
         * Enable rateSummaryRedirection of navigation drawer component if flow = PI
         */
        this.currentUrl = event.url;
        if (this.currentUrl.indexOf('auto/ratesummary') > -1) {
          this.rateSummaryRedirection = true;
        } else {
          this.rateSummaryRedirection = false;
        }
        const urlTree = this.router.parseUrl(this.router.url);
        urlTree.queryParams = {};
        const currentUrl = urlTree.toString().replace('/', '');
        if (currentUrl === 'print' || currentUrl === 'subscriptionAgreement') {
          this.showPrintRouter = true;
        }
      }

      // US399358: Defect Fix - PI - Home and Auto observations
      if (this.router.url.includes('ratesummary')) {
        this.isRateSummaryScreen = true;
      } else {
        this.isRateSummaryScreen = false;
      }
    });
  }

  /** Gets the URL path. To identify two scenarios
   *  1. /fastquote - Entry to application
   *  2. /fastquote/* - Refresh scenario
   */
  isRefreshScenario() {
    let refreshScenario = true;
    const pathName = window.location.pathname ? window.location.pathname.toLowerCase() : null;
    if (pathName && (pathName === '/fastquote' || pathName === '/fastquote/' || pathName === '/')) {
      refreshScenario = false;
    } else if (pathName && (pathName === '/quote' || pathName === '/quote/')) {
      refreshScenario = false;
    }
    return refreshScenario;
  }

  /**
   * This function is to create custom script for googlemap apis
   */
  loadGoogleAutoCompleteScript() {
    const node = this.document.createElement('script');
    node.src = 'https://maps.googleapis.com/maps/api/js?key=' + ServiceConstants.AUTO_COMPLETE_KEY + '&libraries=places';
    node.type = 'text/javascript';
    node.async = true;
    // tslint:disable-next-line: deprecation
    node.charset = 'utf-8';
    this.document.getElementsByTagName('head')[0].appendChild(node);
  }

  /**
   * Handle Refresh
   */
  private handleRefresh() {
    this.routerSubscription = this.appStoreService.getRefreshFlag$.subscribe((isRefreshed) => {
      this.browserRefresh = isRefreshed;
      if (isRefreshed && this.isRefreshScenario()) {
        // US222863: Handling back button secanrio when coming from FWS call center or choice.com static page
        if (sessionStorage.getItem('MetLifeRedirection') &&
        sessionStorage.getItem('MetLifeRedirection').includes('promoid')) {
          window.location.href = this.domSanitizer.sanitize(SecurityContext.URL, sessionStorage.getItem('MetLifeRedirection'));
        }
        if (sessionStorage.getItem('appStore')) {
          const reqpayLoad = JSON.parse(sessionStorage.getItem('appStore'));
          if (reqpayLoad.controlData) {
            // Seeting the Router Refresh param to load in subsequent feature stores
            reqpayLoad.controlData.isRouterRefreshed = isRefreshed;
          }
          // Update the Root Store Directly
          this.appStoreService.updateRootStoreDirectly(reqpayLoad);
          // Call Project Code Status
          this.appStoreService.getProjectStatusCode(this.getEffectiveDate());
        } else {
          // Redirect to Legacy if user accesses any page directly
          window.location.href = this.domSanitizer.sanitize(SecurityContext.URL,
            ServiceConstants.LEGACY_URL + ServiceConstants.LEGACY_URL_COMMON);
        }
      }
    });
  }

  /**
   * This function needs to be called to calculate the effectiveDate
   * paramter passed to Project Status code
   */
  getEffectiveDate() {
    // TODO - Changes to be done here while retrieve Quote is implemented
    const now = new Date();
    return this.datePipe.transform(now, 'yyyy-MM-dd');
  }

  /**
   * Persist any changes in AppStore in SessionStorage
   */
  private persistStore() {
    this.appStoreService.getAppSelector$.subscribe((data: any) => {
      if (data.data && data.data[`quoteNumber`]) {
        sessionStorage.setItem('appStore', JSON.stringify(data));
      }
    });
  }

  closeDrawer(reason: string) {
    if (reason !== 'backdrop') {
      this.sidenav.close();
    }
  }

  expandSideStrip() {

    if (this.showAsStrip === false) {
      return;
    }



    this.navService.hideDrawer();
    const root = this;
    setTimeout(() => {
      root.navService.showDrawer();
    }, 50);

    this.navService.expandSideStrip();

  }

  collapseSidePanel() {


    if (this.showAsStrip === false) {
      return;
    }
    this.navService.hideDrawer();
    const root = this;
    setTimeout(() => {
      root.navService.showDrawer();
    }, 50);


    this.navService.collapseSidePanel();

  }

  /**
   * segment tracking for info pop up
   */
  callSegmentTrackingForInfoDialog(dialogInfo): void {
    if (dialogInfo && dialogInfo.description) {
      const regExp = DataConstants.REGEX;
      dialogInfo.description = dialogInfo.description.replace(regExp.htmlRegex, '');
      dialogInfo.description = dialogInfo.description.replace(regExp.spChar, '');
      dialogInfo.description = dialogInfo.description.replace(regExp.newLine, ' ');
      dialogInfo.description = dialogInfo.description.replace(regExp.spCharSlash, '');
      dialogInfo.title = dialogInfo.title ? dialogInfo.title.replace(regExp.spCharSlash, '') : '';
      const modalInfo: HelpModalOpenedProps = {
        title: dialogInfo.title,
        description: dialogInfo.description
      };
      /** subscription for landing state code */
      this.segmentService.trackHelpModalOpened(modalInfo);
      this.infoService.clearDialogStatus();
    }
  }

  onScrollElement(event?) {
    if (this.currentStep === 1) {
      this.scollValue = event;
      const scrollReferenceElement = document.getElementsByClassName('address-input-position');
      const fixedFooter = document.getElementsByClassName('fixed-footer');

      if (scrollReferenceElement === null || fixedFooter === null) {
        return;
      }

      if (scrollReferenceElement[0]) {
        this.elementIndex = scrollReferenceElement[0].getClientRects();
        const footerIndex = fixedFooter[0].getClientRects();
        const elementTopIndex = this.elementIndex[0].top + 50;
        const footerTopIndex = footerIndex[0].top;
        const pacContainerElement: HTMLElement = document.querySelector('.pac-container');
        pacContainerElement.style.top = elementTopIndex + 'px';
        if (elementTopIndex < 62) {
          pacContainerElement.style.opacity = '0';
        } else if (elementTopIndex + 10 > footerTopIndex) {
          pacContainerElement.style.opacity = '0';
        } else {
          pacContainerElement.style.opacity = '1';
        }
      }
    }
  }


  isLargeScreen() {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (width > 960) {

      return true;
    } else {
      //  this.showAsStrip = false;
      return false;
    }
  }

  showDrawer() {

    this.navService.showAndExpandDrawer();
  }

  ngAfterViewInit() {
    this.switchInProgress = 'app-nav-content-fill';
  }

  navigate(url: any, status: string, previousStepStatus: string, index: any) {
    // clearing the flow when clicked on the navigation.
    this.appStoreService.updateRedirectionFlow({ flowDataForQuote: null });
    const currentUrl = this.router.url.replace('/', '');
    // US153518: EE MVP (NC) - Auto product Driver assignment screen requirements
    this.driverStoreService.getDriverAssignmentData$.subscribe(data => {
      const driverList = JSON.parse(JSON.stringify(data));
      this.noOfInExpDrv = 0;
      this.inExpDrv = false;
      driverList.forEach((driveritem: any) => {
        if (driveritem.inExpDrv) {
          this.inExpDrv = true;
          this.noOfInExpDrv = this.noOfInExpDrv + 1;
        }
      });
    }).unsubscribe();

    // US177580 - Back button disable for PI flow
    this.appStoreService.updateAppControlData({ previousRoute: [currentUrl, currentUrl], displayQuotePremium: false });
    if ((status !== ModuleProgressStatus.NOT_STARTED
      /** DE11188 :
       * When retrieving a business quote and naviagting to confirmation page getting  error 404 in the background
       * and page is clocking forever Navigation to Business confirmation page should not happen directly
       */
      || previousStepStatus === ModuleProgressStatus.COMPLETED) && url.indexOf('business/confirmation') === -1) {
      if (this.checkDriverScreenValidated()) {
        if (index !== this.currentStep || currentUrl !== url[0]) {
          const editedSubcription: Subscription = this.appStoreService.getFormEditedStatus$.subscribe(data => {
            this.edited = data;
          });
          editedSubcription.unsubscribe();
          const editedVehicle: Subscription = this.appStoreService.getVehicleModuleEditedStatus$.subscribe(data => {
            this.vehicleEdited = data;
          });
          editedVehicle.unsubscribe();
          const editedDriver: Subscription = this.appStoreService.getDriverModuleEditedStatus$.subscribe(data => {
            this.driverEdited = data;
          });
          const lastVisitedPageSub: Subscription = this.appStoreService.getAdditionalPageStatus$.subscribe(data => {
            this.additionalPageStatus = data;
          });
          lastVisitedPageSub.unsubscribe();
          this.subscriptions.push(this.appStoreService.getVehicleDriverModuleEditedStatus$.subscribe((data: boolean) => {
            this.isVehicleOrDriverEdited = data;
          }));
          this.rideshareStateRuleModel = this.stateRuleModel.getRideshareStateRule(this.landingStateCode);
          this.driverassignmentStateRuleModel = this.stateRuleModel.getDriverAssignmentStateRule(this.landingStateCode);
          this.assignVehicleDriverStateRuleModel = this.stateRuleModel.getAssignVehicleDriverStateRule(this.landingStateCode);
          editedDriver.unsubscribe();
          this.isSignalBannerDiscountImpl = this.stateRuleModel.getSignalBannerStateRule(this.landingStateCode).isSignalBannerDiscountImpl;
          /** subscription for brightlined status */
          this.subscriptions.push(this.autoStoreService.getBrightLinedData$.subscribe((data: any) => {
            this.isBWQuote = (data && data.autoCompanyCode && data.autoCompanyCode === 'B');
          }));
          /** Subscription for projectstatus code */
          this.subscriptions.push(this.appStoreService.getProjectCodeStatus$.subscribe(projectCodeStatus => {
            this.isFXImpl = projectCodeStatus[`isFlexImpl`];
            this.isConnectedCarImpl = projectCodeStatus[`isConnectedCarImpl`];
            this.isGWImpl = projectCodeStatus[`isGWImpl`];
            this.isFullPackageFullPremiumImpl = projectCodeStatus[`isFullPackageFullPremiumImpl`];
          }));
          /** subscription for Connected Car Eligible Status */
          this.subscriptions.push(this.appStoreService.getConnectedCarStatus$.subscribe((connectedCarEligibleStatus: boolean) => {
            this.isConnectedCarEligible = connectedCarEligibleStatus;
          }));
          /** for checking whether the user skipped the BWPopUp using browser back button */
          let bwPopUpSkipped = false;
          this.appStoreService.getBwPopUpSkipped$.subscribe(data => { bwPopUpSkipped = data; }).unsubscribe();
          /** For checking second perform rate status, when try to navigate to other pages from quote page */

          this.subscriptions.push(this.appStoreService.getDefaultRateQuoteCalled$.subscribe(rateCallStatus => {
            this.secondRateCallStatus = rateCallStatus === RateQuoteProgressStatus.COMPLETED;
          }));
          if (this.isFullPackageFullPremiumImpl) {
            this.secondRateCallStatus = true;
          }
          /* don't let the user to enter quote page via navigation drawer when the value of bwPopUpSkipped = true */
          if (url[0] !== 'auto/quote' || (url[0] === 'auto/quote' && !bwPopUpSkipped)) {
            /** To block navigation from quote till second rate is competed */
            if (this.currentStep === NavigationStepsAutoEnum.Quote && !this.secondRateCallStatus) {
              return 0;
            }
            if (this.edited) {
              if ((url[0] === 'auto/discounts' || url[0] === 'auto/quote') && this.currentStep !== NavigationStepsAutoEnum.Quote) {
                /** US418753: Connected Car changes for quote Modification */
                this.invokeConnectedCarService();
                if (this.driverEdited || this.vehicleEdited) {
                  if (this.driverEdited && this.additionalPageStatus !== ModuleProgressStatus.COMPLETED) {
                    this.router.navigate(['/auto/drivers/additionaldetails']);
                  } else if (this.driverassignmentStateRuleModel.IsDriverAssignment &&
                    (this.totalDriverCount > 1 || this.totalVehicleCount > 1) ||
                    (this.assignVehicleDriverStateRuleModel.isAssignVehicleDriver && this.inExpDrv &&
                      !(this.noOfInExpDrv === 1 && this.totalVehicleCount === 1))) {
                    this.router.navigate(['/auto/drivers/driverassignment']);
                  } else if (url[0] === 'auto/discounts' && this.isSignalBannerDiscountImpl && !this.isBWQuote) {
                    this.spinnerService.showLoader();
                    this.checkIfConnectedCarEligible(url);
                  } else {
                    this.spinnerService.showLoader();
                    this.router.navigate(url);
                  }
                } else if (url[0] === 'auto/discounts' && this.isSignalBannerDiscountImpl && !this.isBWQuote) {
                  this.spinnerService.showLoader();
                  this.checkIfConnectedCarEligible(url);
                } else {
                  this.spinnerService.showLoader();
                  this.router.navigate(url);
                }
              } else {
                this.actionDialogComponent.openDialog({
                  modalHeader: '',
                  modalBody: 'You have made changes to this page. Do you want to discard your changes?',
                  confirmButtonText: 'YES, NAVIGATE AWAY',
                  cancelButtonText: 'NO, CONTINUE'
                });
                this.subscriptions.push(this.libDataService.dialogActionPerformed$.subscribe(
                  action => {
                    this.cancelLeftNavModalAction = action;
                    if (this.cancelLeftNavModalAction === 'Accept') {
                      this.dialog.closeAll();
                      this.appStoreService.updateFormEdited(false);

                      this.router.navigate(url);
                    } else {
                      this.actionDialogComponent.closeDialog({
                        modalHeader: '',
                        modalBody: 'You have made changes to this page. Do you want to discard your changes?',
                        confirmButtonText: 'YES, NAVIGATE AWAY',
                        cancelButtonText: 'NO, CONTINUE'
                      });
                    }
                  }
                ));
              }
            } else {
              if (url[0] === 'auto/discounts' || url[0] === 'auto/quote') {
                /** US418753: Connected Car changes for quote Modification */
                this.invokeConnectedCarService();
                if (this.driverEdited || this.vehicleEdited) {
                  if (this.driverEdited && this.additionalPageStatus !== ModuleProgressStatus.COMPLETED) {
                    this.router.navigate(['/auto/drivers/additionaldetails']);
                  } else if (this.driverassignmentStateRuleModel.IsDriverAssignment &&
                    (this.totalDriverCount > 1 || this.totalVehicleCount > 1) ||
                    (this.assignVehicleDriverStateRuleModel.isAssignVehicleDriver && this.inExpDrv &&
                      !(this.noOfInExpDrv === 1 && this.totalVehicleCount === 1))) {
                    this.router.navigate(['/auto/drivers/driverassignment']);
                  } else if (url[0] === 'auto/discounts' && this.isSignalBannerDiscountImpl && !this.isBWQuote) {
                    this.spinnerService.showLoader();
                    this.checkIfConnectedCarEligible(url);
                  } else {
                    this.spinnerService.showLoader();
                    this.router.navigate(url);
                  }
                } else if (url[0] === 'auto/discounts' && this.isSignalBannerDiscountImpl && !this.isBWQuote) {
                  this.checkIfConnectedCarEligible(url);
                } else {
                  this.spinnerService.showLoader();
                  this.router.navigate(url);
                }
              } else {
                this.spinnerService.showLoader();
                this.router.navigate(url);
              }
            }
          }
        }
      }
    }
  }

  @HostListener('document:keyup')
  @HostListener('document:click')
  @HostListener('document:wheel')
  @HostListener('document:mousemove')
  resetTimer() {
    this.sessionTimeoutService.resetTimer();
    // US164784 : Reset qualtrics timers
    this.qualtricsTimeoutService.resetQualtricsTimer(this.moduleName);
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrWidth = window.innerWidth;
    if (this.scrWidth < 960) {
      this.matdrawerMode = 'over';
      this.openFlag = false;
      this.isExpanded = false;
    } else {
      this.matdrawerMode = 'side';
      this.openFlag = true;
    }
  }

  checkDriverScreenValidated() {
    if (this.stepsCompleted === 3) {
      let contrlData;
      this.appStoreService.appControlData$.subscribe((data) => contrlData = data).unsubscribe();
      // return contrlData.isDriverScreenValidated ? true : false; // sonar code smell fix - rewritten as below
      if (contrlData && contrlData.isDriverScreenValidated) {
        return contrlData.isDriverScreenValidated;
      } else {
        return false;
      }
    }
    return true;
  }

  /**
   * Invoke Connected car service on submit
   */
  invokeConnectedCarService(): void {
    if (this.isVehicleOrDriverEdited && this.isConnectedCarImpl && !this.isBWQuote) {
      const connectedCarRequestObject = {} as ConnectedCarRequest;
      connectedCarRequestObject.quoteNumber = this.quoteNumber;
      connectedCarRequestObject.gWImplemented = this.isGWImpl;
      connectedCarRequestObject.landingStateCode = this.landingStateCode;
      this.appStoreService.vehicleOrDriverChanged(false);
      this.autoStoreService.invokeConnectedCar(connectedCarRequestObject);
    }
  }
  checkIfConnectedCarEligible(url: string[]): void {
    if (this.isConnectedCarImpl && this.isConnectedCarEligible) {
      this.spinnerService.showLoader();
      this.router.navigate(url);
    } else {
      /** DE146373 - Resolved the page keep on loading issue */
      if (this.currentUrl !== '/auto/signal-discounts') {
        this.spinnerService.showLoader();
        this.router.navigate(['/auto/signal-discounts']);
      }
    }
  }
  /**
   * no timeout condition for maintanance/thankyou/knockout pages
   */
  routerEventSubscription() {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.navigationDrawerService.showHeader();
        const urlString = event.url;
        const splittedUrl = urlString.split('/');
        this.moduleName = splittedUrl[2] ? splittedUrl[2] : splittedUrl[1];
        const moduleNameSplit = this.moduleName.split('?');
        // US176305: Display Quote Premium Banner
        this.displayQuotePremium = false;
        if (moduleNameSplit.length > 1) {
          this.moduleName = moduleNameSplit[0];
        }
        if (this.moduleName === '' || this.moduleName === 'existing' || this.moduleName === 'maintenance' ||
          this.moduleName === 'knockout' || this.moduleName === 'thankyou' || this.moduleName === 'inconvenience' ||
          this.moduleName === 'intermediatepage' || this.moduleName === 'intermediatebwpage' || this.moduleName === 'maintenance') {
          this.sessionTimeoutService.stopTimer();
          this.sessionTimeoutService.stopPopupTimer();
        } else {
          this.sessionTimeoutService.startTimer();
        }

        // US177580 - Back button disable for PI flow
        this.location.onPopState(() => {
          let previousRoute = ['', ''];
          this.appStoreService.appControlData$.subscribe((data) => {
            previousRoute = data.previousRoute;
          }).unsubscribe();

          // US386017 - Back button redirection issue fix
          if (previousRoute &&  this.isNavigatedFromLanding(previousRoute)) {
            window.location.href = '/';
            return;
          }

          if (previousRoute && previousRoute[0] === 'legacy') {
            this.legacyRedirectionService.redirectToLegacy([]);
          }
          // US246464: API Partner - Change 'Next' path in RC1 summary
          if (previousRoute && this.isNavigatedFromRateSummary(previousRoute)) {
            history.go(1);
          } else {
            // disable RC1 premium banner on browser back
            this.appStoreService.updateAppControlData({ displayQuotePremium: false });
          }
        });


        // US160958 - start qualtrics timer & load Qualtrics API
        this.isInactivityTimeReached = false;
        // unload qualtrics API
        if (window[this.qSI]) {
          window[this.qSI].API.unload();
        }
        if (this.moduleName === 'discounts') {
          // US176305: Display Quote Premium Banner
          this.appStoreService.appControlData$.subscribe((data) => {
            this.premiumAmtObj = data.quotePremiumAmount;
            this.displayQuotePremium = data.displayQuotePremium;
          }).unsubscribe();
          this.qualtricsTimeoutService.startQualtricsTimer(this.moduleName);
        } else if (this.moduleName !== 'quote') {
          this.loadQualtricsAPI();
        }
        if (this.moduleName === 'existing' || this.moduleName === 'maintenance' ||
          this.moduleName === 'knockout' || this.moduleName === 'thankyou' || this.moduleName === 'inconvenience' ||
          this.moduleName === 'intermediatepage' || this.moduleName === 'intermediatebwpage' || this.moduleName === 'maintenance') {
          this.location.onPopState(() => {
            history.go(1);
          });
        }
      }
    });
  }

  isNavigatedFromRateSummary(previousRoute: string[]): boolean {
    return (previousRoute[0] === 'ratesummary' && previousRoute[1] === 'vehicles') ||
      (previousRoute[0] === 'ratesummary' && previousRoute[1] === 'yourinfo');
  }

  isNavigatedFromLanding(previousRoute: string[]): boolean {
    return (previousRoute[0] === 'legacy' && previousRoute[1] === 'your-info');
  }

  /**
   * Load qualtrics API
   */
  loadQualtricsAPI() {
    if (window[this.qSI]) {
      window[this.qSI].API.unload();
      window[this.qSI].API.load().done(window[this.qSI].API.run());
    }
  }
  /**
   * Load Qualtrics API after certain inactivity
   */
  QualtricsTimerSubscription() {
    this.subscriptions.push(
      this.qualtricsTimeoutService.timerComplete.subscribe((res) => {
        // US182753:Auto refresh issue fix
        this.isInactivityTimeReached = true;
        this.loadQualtricsAPI();
      })
    );
  }

  IdleTimeoutSubcription() {
    this.sessionTimeoutService.startTimer();
    this.idleTimerSubscription = this.sessionTimeoutService.timeoutExpired.subscribe(res => {
      const data = {
        dialogTitle: `Your Session is About to Expire.`,
        dialogContent: `Click 'Continue' to keep working or 'Exit' to
                        save your quote and close the application.`,
        cancelButtonText: 'Exit',
        confirmButtonText: 'Continue'
      };
      this.dialog.closeAll();
      this.dialogRef = this.helperService.openActionDialog(data);
      this.SaveQuotePopUp();
      this.dialogRef.afterClosed().subscribe(aboutExpirepopup => {
        if (aboutExpirepopup && aboutExpirepopup.action === 'accept') {
          const request = {
            quoteNumber: this.quoteNumber
          };
          this.appStoreService.sessionKeepAlive(request);
          this.subscriptions.push(this.appStoreService.getAppControlData$.subscribe((appStatus: any) => {
            if (appStatus.sessionKeepAlive === 'success') {
              this.sessionTimeoutService.resetTimer();
              this.sessionTimeoutService.stopPopupTimer();
            }
          }));
        } else if (aboutExpirepopup && aboutExpirepopup.action === 'cancel' && !this.emailAddress && this.moduleName !== 'yourinfo') {
          this.dialogTitle = 'Session TimeOut With Email';
          this.dialog.closeAll();
          this.sessionTimeoutService.stopTimer();
          this.SaveQuotePopUp();
        } else if (aboutExpirepopup && aboutExpirepopup.action === 'cancel' && !this.emailAddress && this.moduleName === 'yourinfo') {
          this.dialogTitle = 'Session TimeOut WithOut Email';
          this.dialog.closeAll();
          this.sessionTimeoutService.stopTimer();
          this.SaveQuotePopUp();
        }
      });
    });
  }

  SaveQuotePopUp() {
    if (!this.dialogTitle) {
      this.sessionTimeoutService.startPopupTimer();
      this.sessionTimeoutService.popupTimeoutExpired.subscribe(redirectData => {
        this.dialog.closeAll();
        if (this.moduleName === 'yourinfo') {
          window.location.replace('/');
        } else {
          this.saveAndFinishLaterObject = this.navigationDrawerService.createRequestPayload(this.emailAddress);
          this.appStoreService.saveAndFinishLater(this.saveAndFinishLaterObject);
          this.dialog.open(SaveAndFinishOnTimeoutComponent,
            {
              panelClass: 'save-and-finish-on-timeout',
              data: { dialogTitle: 'TimeOut Expired' },
              disableClose: true
            });
          this.sessionTimeoutService.stopTimer();
          this.sessionTimeoutService.stopPopupTimer();
        }
      });
    } else {
      this.dialog.open(SaveAndFinishOnTimeoutComponent,
        {
          panelClass: 'save-and-finish-on-timeout',
          data: { dialogTitle: this.dialogTitle },
          disableClose: true
        });
      this.sessionTimeoutService.stopTimer();
      this.sessionTimeoutService.stopPopupTimer();
    }
  }
  /**
   * US221089: Auto driver count mismatch
   */
  hideAdditionalInfo(): void {
    this.appStoreService.updateAutoNavData({ additionalInfo: ModuleProgressStatus.REMOVE });
  }

  /**
   * on destroy
   */
  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.idleTimerSubscription.unsubscribe();
  }
}
