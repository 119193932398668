import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Router } from '@angular/router';

/* Components */
import { ModalComponent, HeaderPanelComponent, NavigationDrawerComponent } from './components';
import { AgentDetailsComponent } from './components/agent-details/agent-details.component';
import { FooterLinksComponent } from './components/footer-links/footer-links.component';
import { MatIconModule } from '@angular/material/icon';
import { ExistingPolicyComponent } from './components/existing-policy/existing-policy.component';
import { FfqLibraryModule } from '@ffq-lib';
import { BristolWestPopupComponent } from './components/bristol-west-popup/bristol-west-popup.component';
import { SaveAndFinishComponent } from './components/save-and-finish/save-and-finish.component';
import { SaveAndFinishOnTimeoutComponent } from './components/save-and-finish-on-timeout/save-and-finish-on-timeout.component';
import { InconvenienceComponent } from './components/inconvenience/inconvenience.component';
import { IntermediateComponent } from './components/intermediate-page/intermediate-page.component';
import { IntermediateBWComponent } from './components/intermediate-bw-page/intermediate-bw-page.component';
import { AutoQuoteComponent } from './components/auto-quote-page/auto-quote-page.component';
import { BusinessQuoteComponent } from './components/business-quote-page/business-quote-page.component';
import { AutoRentersProgressComponent } from './components/auto-renters-progress/auto-renters-progress.component';
import { IidKnockoutComponent } from './components/iid-knockout/iid-knockout.component';
import { ScheduleAgentAppointmentComponent } from './components/schedule-agent-appointment/schedule-agent-appointment.component';
import { ScheduleAgentConfirmationComponent } from './components/schedule-agent-confirmation/schedule-agent-confirmation.component';
/* Services */
// import { ModalService } from './services';

/* Modules */

@NgModule({
  // tslint:disable-next-line:max-line-length
  declarations: [ModalComponent, HeaderPanelComponent, NavigationDrawerComponent, AgentDetailsComponent, ExistingPolicyComponent,
  BristolWestPopupComponent, FooterLinksComponent, SaveAndFinishComponent, SaveAndFinishOnTimeoutComponent,
  InconvenienceComponent, IntermediateComponent, IntermediateBWComponent, AutoQuoteComponent, BusinessQuoteComponent,
  AutoRentersProgressComponent,
  IidKnockoutComponent,
  ScheduleAgentAppointmentComponent,
  ScheduleAgentConfirmationComponent],
  imports: [CommonModule, ReactiveFormsModule, RouterModule, MatIconModule, FfqLibraryModule],
  exports: [CommonModule, ReactiveFormsModule, FfqLibraryModule, HeaderPanelComponent, NavigationDrawerComponent,
  MatIconModule, ExistingPolicyComponent, AgentDetailsComponent, InconvenienceComponent, IntermediateComponent, IntermediateBWComponent,
  AutoQuoteComponent, BusinessQuoteComponent],
  providers: [
    // ModalService
  ],
  entryComponents: [BristolWestPopupComponent, SaveAndFinishComponent, SaveAndFinishOnTimeoutComponent, AutoRentersProgressComponent]
})
export class SharedModule { }
