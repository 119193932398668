import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AutoState } from '.';
import * as fromAutoSelectors from './auto.selectors';
import * as fromAutoActions from '@ffq-app-auto/store/auto.actions';
import * as fromAppActions from '@ffq-app-store/app.actions';
import { SaveExcludedDriversRequest, PrequalifiedDiscounts, ConnectedCarRequest } from '@ffq-app-shared/model/common.data.model';

@Injectable({
  providedIn: 'root'
})
export class AutoStoreService {


  /**
   * Creates an instance of auto store service.
   * @param autoStore autoStore
   */
  constructor(private autoStore: Store<AutoState.AppState>) { }
  /**
   * Get bright lined data$ of auto store service
   */
  getBrightLinedData$ = this.autoStore.select(fromAutoSelectors.getBrightLinedData);


  getAutoSelector$ = this.autoStore.select(fromAutoSelectors.getAutoSelector);
  /**
   * Get discount save$ of auto store service
   */
  getDiscountSave$ = this.autoStore.select(fromAutoSelectors.getDiscountSave);

  /**
   * Get signal discount save$ of auto store service
   */
  getSignalDiscountSave$ = this.autoStore.select(fromAutoSelectors.getSignalDiscountSave);

  /**
   * Get quote save$ of auto store service
   */
  getQuoteSave$ = this.autoStore.select(fromAutoSelectors.getQuoteSave);

  /**
   * Get quote save$ of auto store service
   */
  getRideShareSave$ = this.autoStore.select(fromAutoSelectors.getRideShareSave);

  /**
   * Get DriverAssignment save$ of auto store service
   */
  getDriverAssignSave$ = this.autoStore.select(fromAutoSelectors.getDriverAssignSave);

  /**
   * get data for rate quote$ of auto store service
   */
  getDataForRateQuote$ = this.autoStore.select(fromAutoSelectors.getDataForRateQuote);

  /**
   * Get vehicle ride share data$ of auto store service
   */
  getVehicleRideShareData$ = this.autoStore.select(fromAutoSelectors.getVehicleRideShare);

  /**
   * Get eligible for chat bot$ of quote store service
   */
  getEligibleForChatBot$ = this.autoStore.select(fromAutoSelectors.getEligibleForChatBot);
  /**
   * Get quote summary data of quote store service
   */
  getQuoteRateData$ = this.autoStore.select(fromAutoSelectors.getQuoteRateData);
  /**
   * Get discounts$ of quote store service
   */
  getQuoteDiscount$ = this.autoStore.select(fromAutoSelectors.getQuoteDiscount);

  /**
   * Get quote data$ of auto store service
   */
  getQuoteData$ = this.autoStore.select(fromAutoSelectors.getQuoteData);

  /**
   * Get knockout response$ of auto store service
   */
  getKnockOutResponse$ = this.autoStore.select(fromAutoSelectors.getKnockOutResponse);

  /**
   * Get knockout response$ of auto store service
   */

  getSaveActionResponse$ = this.autoStore.select(fromAutoSelectors.getSaveActionResponse);

  /**
   * Get knockout response$ of auto store service
   */
  getMDKnockoutInd$ = this.autoStore.select(fromAutoSelectors.getKnockOutResponse);

  /**
   * Get quote liability coverage data$ of auto store
   */
  getQuoteLiabilityCov$ = this.autoStore.select(fromAutoSelectors.getQuoteLiabilityCov);

  /**
   * Get vehicle coverage data$ of quote store service
   */
  getVehicleCoverageData$ = this.autoStore.select(fromAutoSelectors.getVehicleCoverageData);

  /**
   * Get vehicles$ of auto store service
   */
  getVehicles$ = this.autoStore.select(fromAutoSelectors.getVehicles);

  /**
   * Get prefill vehicle data$ of auto store service
   */
  getPrefillVehicleData$ = this.autoStore.select(fromAutoSelectors.getPrefillVehicleData);

  /**
   * Get app vin look up vehicle$ of auto store service
   */
  getAppVinLookUpVehicle$ = this.autoStore.select(fromAutoSelectors.getAppVinLookUpVehicle);

  getValidateLicense$ = this.autoStore.select(fromAutoSelectors.getValidateLicense);

  /**
   * Get discount data$ of auto store service
   */
  getDiscountData$ = this.autoStore.select(fromAutoSelectors.getDiscountData);
  /**
   * Get discount data$ of auto store service
   */
  getRc1PremiumAmt$ = this.autoStore.select(fromAutoSelectors.getRc1PremiumAmt);
  /**
   * Get Auto errors$ of auto store service
   */
  getAutoErrors$ = this.autoStore.select(fromAutoSelectors.getAutoErrors);

  /** Driver Selectore */
  prefillDriversData$ = this.autoStore.select(fromAutoSelectors.getPrefillDriversData);

  selectedPrefillDriverData$ = this.autoStore.select(fromAutoSelectors.getSelectedPrefillDriver);
  driversData$ = this.autoStore.select(fromAutoSelectors.getDriverData);
  selectedDriverData$ = this.autoStore.select(fromAutoSelectors.getSelectedDriverData);
  getPrefillPniDriverData$ = this.autoStore.select(fromAutoSelectors.getPrefillPniDriverData);
  getPrefillCurrInsuredAnswer$ = this.autoStore.select(fromAutoSelectors.getPrefillCurrInsuredAnswer);
  getDriverCurrInsuredAnswer$ = this.autoStore.select(fromAutoSelectors.getDriverCurrInsuredAnswer);
  getpaymentDetails$ = this.autoStore.select(fromAutoSelectors.getPaymentDetails);
  getServiceControlData$ = this.autoStore.select(fromAutoSelectors.getServiceControlData);
  getRideShareData$ = this.autoStore.select(fromAutoSelectors.getRideShareData);
  getAutoControlData$ = this.autoStore.select(fromAutoSelectors.getAutoControlData);
  getPNIDisableParameters$ = this.autoStore.select(fromAutoSelectors.getPNIDisableParameters);
  getPrefillDrivers$ = this.autoStore.select(fromAutoSelectors.getPrefillDrivers);
  /**
   * Get excludeddrivers$ of auto store service
   */
  getExcludedDriverSaveStatus$ = this.autoStore.select(fromAutoSelectors.getExcludedDriverSaveStatus);
  /**
   * Get getPrequalifiedDiscounts$ of auto store service
   */
  getPrequalifiedDiscounts$ = this.autoStore.select(fromAutoSelectors.getPrequalifiedDiscounts);
  /**
   * Get SaveVehcleList service status
   */
  getSaveVehcleListServiceStatus$ = this.autoStore.select(fromAutoSelectors.getSaveVehcleListServiceStatus);

  /**
   * Determines whether other model vehicles$ has
   */
  hasOtherModelVehicles$ = this.autoStore.select(fromAutoSelectors.hasOtherModelVehicles);

  /**
   * Get ele link flag of auto store service
   */
  getEleLinkFlag$ = this.autoStore.select(fromAutoSelectors.getEleLinkFlag);

  /**
   * Determines whether eleenabled$ is
   */
  isELEEnabled$ = this.autoStore.select(fromAutoSelectors.isELEEnabled);

  // Get coverage updated of auto store service
  getSecondRateCallStatus$ = this.autoStore.select(fromAutoSelectors.getSecondRateCallStatus);

  /**
   * US501061 && US501062: CA FFQ Digital Monetization - CA State TFN Replacement
   * for Agent, Quote, Thank you and print quote pages for desktop and mobile windows
   */
  getBaseRateCallStatus$ = this.autoStore.select(fromAutoSelectors.getBaseRateCallStatus);

  getEAAgentStatus$ = this.autoStore.select(fromAutoSelectors.getEAAgentStatus);

  // Get eligible for auto renters bundle$ of auto store service
  getEligibleForAutoRentersBundle$ = this.autoStore.select(fromAutoSelectors.getEligibleForAutoRentersBundle);

  // Get renters monthly premium amount of auto store service
  getRentersMonthlyPremiumAmt$ = this.autoStore.select(fromAutoSelectors.getRentersMonthlyPremiumAmt);

  // Get renters rate service status$ of auto store service
  getRentersRateServiceStatus$ = this.autoStore.select(fromAutoSelectors.getRentersRateServiceStatus);

  // get all the the drivers
  getDriverList$ = this.autoStore.select(fromAutoSelectors.getDriverList);
  // get errorCount from control data
  getErrorCount$ = this.autoStore.select(fromAutoSelectors.getErrorCount);

  // get pni driver
  getPniDriverData$ = this.autoStore.select(fromAutoSelectors.getPniDriverData);
   // get adpfExcludeDriver data from retrieve quote
   getAdpfExcludeDriverData$ = this.autoStore.select(fromAutoSelectors.getAdpfExcludeDriverData);

  getPrefillServiceStatus$ = this.autoStore.select(fromAutoSelectors.getPrefillServiceStatus);

  getSavedCarrierInfo$ = this.autoStore.select(fromAutoSelectors.getSavedCarrierInfo);

  /**
   * Get dnqmessages from auto store
   */
  getDNQMessages$ = this.autoStore.select(fromAutoSelectors.getDNQMessages);
  /**
   * Gets driver data with ref$
   * @ param index
   * @ returns
   */
  getDriverDataWithRef$(index) {
    return this.autoStore.select(fromAutoSelectors.getDriverDataWithRef, { drivRef: index });
  }
  selectPrefillDriver(ctrlData: any) {
    this.autoStore.dispatch(fromAutoActions.SelectPrefillDriver({ ctrlData }));
  }
  selectDriver(ctrlData: any) {
    this.autoStore.dispatch(fromAutoActions.SelectDriver({ ctrlData }));
  }
  clearDriverDataSelection(ctrlData: any) {
    this.autoStore.dispatch(fromAutoActions.ClearSelectedDriver({ ctrlData }));
  }
  clearPrefillDriverDataSelection(ctrlData: any) {
    this.autoStore.dispatch(fromAutoActions.ClearSelectedPrefillDriver({ ctrlData }));
  }
  clearValidateLicense(respData: any) {
    this.autoStore.dispatch(fromAutoActions.ClearValidateLicense({ respData }));
  }
  initiatePNIDriver(reqPayload: any) {
    this.autoStore.dispatch(fromAutoActions.InitiatePNIDriver({ reqPayload }));
  }
  initiatePNISpouse(reqPayload: any) {
    this.autoStore.dispatch(fromAutoActions.InitiatePNISpouse({ reqPayload }));
  }
  initiateDriverSpouse(reqPayload: any) {
    this.autoStore.dispatch(fromAutoActions.InitiateDriverSpouse({ reqPayload }));
  }
  /**
   * Vins look up
   * @param reqPayload Request payload
   */
  vinLookUp(reqPayload: any) {
    this.autoStore.dispatch(fromAutoActions.VINLookup({ reqPayload }));
  }
  /**
   * Driver Vehicle Assignment
   * @ param reqPayload
   */

  driverVehicleAssignment(reqPayload: any) {
    this.autoStore.dispatch(fromAutoActions.DriverVehicleAssignment({ reqPayload }));
  }

  vehicleDriverAssignment(reqPayload: any) {
    this.autoStore.dispatch(fromAutoActions.VehicleDriverAssignment({ reqPayload }));
  }
  // US151849: Renters to Auto Cross Sell: Spouse appears twice on Driver Page for cross sell Auto
  removeDriverFromPrefillList(prefillDriverReference: string) {
    this.autoStore.dispatch(fromAutoActions.RemoveDriverFromPrefillList({ reqPayload: prefillDriverReference }));
  }
  /**
   * Saves vehicle
   * @param reqPayload Request payload
   */
  saveVehicle(reqPayload: any) {
    switch (reqPayload.saveMode) {
      case 'delete':
        this.autoStore.dispatch(fromAutoActions.DeleteVehicle({ reqPayload }));
        break;
      case 'add':
        this.autoStore.dispatch(fromAutoActions.SaveVehicle({ reqPayload }));
        break;
      case 'update':
        this.autoStore.dispatch(fromAutoActions.EditVehicle({ reqPayload }));
        break;
    }
  }

  invokeVehicleSaveActionsService(reqPayload: any): void {
    if (reqPayload.action.includes('NC_SUBSCRIPTION')) {
      this.autoStore.dispatch(fromAutoActions.SaveActionWaitAndFire({ reqPayload }));
    } else {
      this.autoStore.dispatch(fromAutoActions.SaveActionFireAndForget({ reqPayload }));
    }
  }

  /**
   * Updates auto control data
   * @param reqPayload reqPayload
   */
  updateAutoControlData(ctrlData: any) {
    this.autoStore.dispatch(fromAutoActions.UpdateAutoControlData({ ctrlData }));
  }

  /**
   * Updates auto service control data
   * @param reqPayload reqPayload
   */
  UpdateAutoServiceControlData(serviceCtrlData: any) {
    this.autoStore.dispatch(fromAutoActions.UpdateAutoServiceControlData({ serviceCtrlData }));
  }


  /**
   * Updates control data for clear store data
   * @param ctrlData reqPayload
   */
  updateClearStoreData(ctrlData: any) {
    this.autoStore.dispatch(fromAutoActions.UpdateClearStoreData({ ctrlData }));
  }

  // /**
  //  * Determines whether discount save is
  //  * @param reqPayload reqPayload
  //  */
  // isDiscountSave(reqPayload: any) {
  //   this.autoStore.dispatch(fromAutoActions.IsDiscountSave({ reqPayload }));
  // }

  // /**
  //  * Determines whether quote save is
  //  * @param reqPayload reqPayload
  //  */
  // isQuoteSave(reqPayload: any) {
  //   this.autoStore.dispatch(fromAutoActions.IsQuoteSave({reqPayload}));
  // }

  // /**
  //  * Determines whether DriverAssignment save is
  //  * @param reqPayload reqPayload
  //  */
  // isDriverAssignment(reqPayload: any) {
  //   this.autoStore.dispatch(fromAutoActions.IsDriverAssignment({reqPayload}));
  // }

  isModuleSaveAndFinish(reqPayload: any) {
    this.autoStore.dispatch(fromAutoActions.IsModuleSaveAndFinish({ reqPayload }));
  }

  /**
   * Performs default quote rate
   * @param reqPayload RequestPayload
   */
  performDefaultQuoteRate(reqPayload: any) {
    this.autoStore.dispatch(fromAutoActions.PerformDefaultRateQuote({ reqPayload }));
  }

  /**
   * Saves discount
   */
  saveDiscount(reqPayload: any) {
    this.autoStore.dispatch(fromAutoActions.SaveDiscount({ reqPayload }));
  }

  /**
   * Saves discount
   */
  saveSignalDiscount(reqPayload: any) {
    this.autoStore.dispatch(fromAutoActions.SaveSignalDiscount({ reqPayload }));
  }

  /**
   * Saves discount and finish
   * @param reqPayload reqPayload
   */
  saveDiscountAndFinish(reqPayload: any) {
    this.autoStore.dispatch(fromAutoActions.SaveDiscountAndFinish({ reqPayload }));
  }

  updateDriverListSuccess(reqPayload: any) {
    this.autoStore.dispatch(fromAutoActions.UpdateDriverListSuccess({ reqPayload }));
  }

  /**
   * Saves discount and finish
   * @param reqPayload reqPayload
   */
  saveSignalDiscountAndFinish(reqPayload: any) {
    this.autoStore.dispatch(fromAutoActions.SaveSignalDiscountAndFinish({ reqPayload }));
  }

  /**
   * Saves DriverAssignment and finish
   * @param reqPayload reqPayload
   */
  saveDriverAssignAndFinish(reqPayload: any) {
    this.autoStore.dispatch(fromAutoActions.SaveDriverAssignAndFinish({ reqPayload }));
  }
  /**
   * Resets discount state
   */
  resetDiscountAndQuoteState() {
    this.autoStore.dispatch(fromAutoActions.ResetDiscountAndQuoteState());
  }
  /** Updates pnidriver
   * @param reqPayload request payload
   */
  updatePNIDriver(reqPayload: any) {
    //  changed to make it call Actual service
    this.autoStore.dispatch(fromAutoActions.SavePNIDriver({ reqPayload }));
  }

  addPNISpouse(reqPayload: any) {
    this.autoStore.dispatch(fromAutoActions.AddPNISpouseDriver({ reqPayload }));
  }

  addAdditionalDriver(reqPayload: any) {
    this.autoStore.dispatch(fromAutoActions.AddAdditionalDriver({ reqPayload }));
  }
  /**
   * Add driver from prefill data on click of 'Add to Quote'
   *
   */
  addAdditionalDriverFromPrefill(reqPayload: any) {
    this.autoStore.dispatch(fromAutoActions.AddAdditionalDriverFromPrefill({ reqPayload }));
  }
  /**
   * Edits additional driver
   * @ param reqPayload
   */
  editAdditionalDriver(reqPayload: any) {
    this.autoStore.dispatch(fromAutoActions.EditAdditionalDriver({ reqPayload }));
  }
  /**
   * Deletes additional driver
   * @ param reqPayload
   */
  deleteAdditionalDriver(reqPayload: any) {
    this.autoStore.dispatch(fromAutoActions.DeleteAdditionalDriver({ reqPayload }));
  }
  removeMarriedAdditionalDriver(reqPayload: any) {
    this.autoStore.dispatch(fromAutoActions.RemoveMarriedAdditionalDriver({ reqPayload }));
  }

  removeMarriedAdditionalDriverDummy(reqPayload: any) {
    this.autoStore.dispatch(fromAutoActions.RemoveMarriedAdditionalDriverDummy({ reqPayload }));
  }

  /**
   * Edit driver
   * @ param reqPayload
   */
  editDriver(reqPayload: any) {
    this.autoStore.dispatch(fromAutoActions.EditDriver({ reqPayload }));
  }

  /**
   * Update distantStudnet
   * @ param reqPayload
   */
  updateDistantStudent(reqPayload: any) {
    this.autoStore.dispatch(fromAutoActions.updateDistantStudent({ reqPayload }));
  }

  /**
   * Edit and delete driver
   * @ param reqPayload
   */
  editAndDeleteDriver(reqPayload: any, spouseRef: any) {
    this.autoStore.dispatch(fromAutoActions.EditAndDeleteDriver({ reqPayload, spouseRef }));
  }

  validateLicenseNumber(reqPayload: any) {
    this.autoStore.dispatch(fromAutoActions.ValidateLicenseNumber({ reqPayload}));
  }
  /**
   * Edit the driver and delete dummy spouse
   * @ param reqPayload
   */
  editAndDeleteDummyDriver(reqPayload: any, spouseRef: any) {
    this.autoStore.dispatch(fromAutoActions.EditAndDeleteDummyDriver({ reqPayload, spouseRef }));
  }

  /**
   * Adds driver spouse from prefill
   * @ param reqPayload
   */
  addDriverSpouseFromPrefill(reqPayload: any) {
    this.autoStore.dispatch(fromAutoActions.AddDriverSpouseFromPrefill({ reqPayload }));
  }


  /**
   * Saves pni driver additonal data
   */
  savePniDriverAdditonalData(reqPayload: any) {
    this.autoStore.dispatch(fromAutoActions.SavePNIDriverAdditionalData({ reqPayload }));
  }

  /**
   * Updates quote data for rq
   * @param reqPayload reqPayload
   */
  updateAutoStoreFromRQ(reqPayload: any) {
    this.autoStore.dispatch(fromAutoActions.UpdateAutoStoreFromRQ({ reqPayload }));
  }

  /**
   * Save knockout service call
   * @param reqPayload reqPayload
   */
  saveKnockOut(reqPayload: any) {
    this.autoStore.dispatch(fromAutoActions.SaveKnockOut({ reqPayload }));
  }

  /**
   * Agent Assignment for BW
   */
  bwAgentAssignment() {
    this.autoStore.dispatch(fromAppActions.BWAgentAssignment());
  }

  /**
   * Update  driver bw Popup shown
   */
  updateDriverBWPopUpShown() {
    this.autoStore.dispatch(fromAutoActions.BWActionPopupShown());
  }

  /**
   * Update  AutoCompanyCode in Auto Store
   */
  updateAutoCompanyCode(reqPayload: any) {
    this.autoStore.dispatch(fromAutoActions.UpdateAutoCompanyCode({ reqPayload }));
  }

  /**
   * Save vehcle list
   */
  saveVehicleList(reqPayload: any) {
    this.autoStore.dispatch(fromAutoActions.SaveVehicleList({ reqPayload }));
  }
  /* add ride share
   * @ param reqPayload
   */
  addRideShare(reqPayload: any) {
    this.autoStore.dispatch(fromAutoActions.AddRideShare({ reqPayload }));
  }
  /*save ride share and finish
   */
  saveRideShareandFinish(reqPayload: any) {
    this.autoStore.dispatch(fromAutoActions.SaveRideShareAndFinish({ reqPayload }));
  }

  /**
   * Reset Vin Response
   */
  resetVinResponse() {
    this.autoStore.dispatch(fromAutoActions.ResetVinResponse());
  }

  /**
   * Updates auto error
   * @param respError error object
   */
  updateAutoError(respError: any ) {
    this.autoStore.dispatch(fromAutoActions.UpdateAutoError({ respError }));
  }

  /**
   * Calls renters rate service
   */
  callRentersRate() {
    this.autoStore.dispatch(fromAutoActions.RentersRate());
  }

  /**
   * Updates auto store directly
   * @param reqPayload reqPayload
   */
  updateAutoStoreDirectly(reqPayload: any) {
    this.autoStore.dispatch(fromAutoActions.UpdateAutoStoreDirectly({ reqPayload }));
  }

  /**
   * Updates renters rate
   * @param reqPayload reqPayload
   */
  updateRentersRate(reqPayload) {
    this.autoStore.dispatch(fromAutoActions.UpdateRentersRateData({reqPayload}));
  }

  /**
   * Updates renters rate
   * @param reqPayload reqPayload
   */
  dispatchPrint(reqPayload) {
    this.autoStore.dispatch(fromAutoActions.dispatchPrint({reqPayload}));
  }

  /**
   * Saves excluded driver
   */
  saveExcludedDriver(reqPayload: SaveExcludedDriversRequest) {
    this.autoStore.dispatch(fromAutoActions.SaveExcludedDriver({ reqPayload }));
  }
  /**
   * invokeConnectedCar
   */
  invokeConnectedCar(reqPayload: ConnectedCarRequest) {
    this.autoStore.dispatch(fromAutoActions.InvokeConnectedCar({ reqPayload }));
  }

  prefillData(reqPayload: any) {
    this.autoStore.dispatch(fromAutoActions.PrefillData({ reqPayload }));
  }

  updatePrequalifiedDiscounts(prequalifiedDiscounts: PrequalifiedDiscounts[]) {
    this.autoStore.dispatch(fromAutoActions.UpdatePrequalifiedDiscountList({ prequalifiedDiscounts }));
  }
}
